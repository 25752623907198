import {  
  getRequestUserCategories,
  postRequestSignedUrls, 
  getRequestAssetsByQuery } from '../../assets/requests';
  
  function GetNavigation(props) {
    const userId = props.userId;
    
    function orderEvents(events){
      events.sort(function(event1, event2){
        if (event1.name > event2.name) {
          return 1
        } else if (event1.name < event2.name) {
          return -1
        } else {
          return 0
        }
      });
      return events
    }

    function orderYears(years){
      return years.sort(function(years1, years2){return years1.name - years2.name});
    }

    async function getCandidateAsset(categoryId, selectedCategories) {
      let uniqueCategories = [categoryId, ...selectedCategories]
      uniqueCategories = [ ...new Set(uniqueCategories)]
      let candidate = await getRequestAssetsByQuery({ userId, params: { cat: uniqueCategories, page: 1, limit: 10 }})
      return candidate
    }

    async function getSignedUrls(userId, uri) {
      let urls = await postRequestSignedUrls({ userId: userId, params: {}, payload: [uri] })
      return urls
    }

    function capitalizeFirstLetter(string) {
      if (!string) return string; // Ensure the input is not null, undefined, or an empty string
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    async function BaseNavigation(props) {
      let categories = await getRequestUserCategories({ userId: props.userId, log: 'User Categories'});
      let selectedCategories = props.selectedCategories;
      let dispatch = props.dispatch;
      let events = [];
      //let kinCategories = await fetchKinCategories(userId);
      let kinEvents = [];
      let years = [];

      for (let index = 0; index < categories?.length; index++) {
        let category = categories[index]
        let categoryIds = selectedCategories.map( category => category.id )
        // Test for availability of thumb
        let result = await getCandidateAsset(category.id, categoryIds)
        if ((result.length === 0 ) && (!category.subType)){ continue; } 
        let fullKey;
        
        if (result[0] && result[0].thumbnails && result[0].thumbnails.length > 0) {
          // choosing 200px thumb here
          let parsedKey = result[0].thumbnails[1].split('/')
          let slicedKey = parsedKey.slice(4, parsedKey.length)
          slicedKey.unshift('thumb')
          fullKey = slicedKey.join('/')            
        } else {
          let srcKey = result[0]?.src || ''
          fullKey = 'image/' + srcKey
        }
        result = await getSignedUrls(userId, fullKey)
        
        if (result.length === 0 ){ /* Do nothing */ }
        category.src = result[0]
         if (category.type === 'event') {
            if (category.subType != null) {
              category.name = capitalizeFirstLetter(category.name)
              kinEvents.push(category)
            } else {
              events.push(category)
            }
          } else if (category.type === 'year') {
            years.push(category)
        }
      }
      
      events = orderEvents(events)
      kinEvents = orderEvents(kinEvents)
      years = orderYears(years)

      let sc = props.selectedCategories;
      let results = [];
      let selectedCategoryName = sc.length > 0 ? sc[0].name : null;

      if (sc.length === 2) { 
        results = [] 
      } else if (sc.length === 1 && sc[0].type === 'event' && years.length > 0) {
        results = [
          {name: selectedCategoryName || 'Years', subCategories: years} 
        ]
      } else if (sc.length === 1 && sc[0].type === 'year' && events.length > 0) {
        results = []
      } else if (sc.length === 0 && events.length > 0 && years.length > 0) {
          if (events.length > 0) {
            results.push({name: 'Events', subCategories: events })
          }
          if (kinEvents.length > 0) {
            results.push({ name: 'Kin-Categories', subCategories: kinEvents });
          }
          if (years.length > 0) {
            results.push({name: 'Years', subCategories: years})
          }
      }

      if (results.length === 0 ) {
        dispatch({type: 'set_show_assets', payload: true })
      } else {
        dispatch({type: 'set_show_assets', payload: false })
      }

      return results

    } 

    return BaseNavigation(props)
}

export default GetNavigation