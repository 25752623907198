import React, { useState } from 'react';
import userAvatar from '../../assets/images/user-avatar.jpg';
import rightArrow from '../../assets/images/46_Arrow_Right.jpg';
import reloadArrow from '../../assets/images/reload-arrow2.jpg';

const FriendsAndFamilyCategory = () => {
    const [memberName, setMemberName] = useState(''); 
    const [members, setMembers] = useState([]);
    const [avatarStartIndex, setAvatarStartIndex] = useState(0); 

    const avatars = new Array(10).fill(userAvatar); 

    const handleAddFriendOrFamily = () => {
        if (memberName) {
            setMembers(prev => [...prev, memberName]);
            setMemberName('');
        }
    };

    const handleShowMoreAvatars = () => {
        if (avatarStartIndex + 4 < avatars.length) {
            setAvatarStartIndex(prev => prev + 4);
        } else {
            setAvatarStartIndex(0); 
        }
    };

    const isAtEnd = avatarStartIndex + 4 >= avatars.length; 

    return (
        <div className="friends-family-category">
            <h2 className="category-header">Friends & Family</h2>
            <input
                type="text"
                placeholder="Member Name"
                value={memberName}
                onChange={(e) => setMemberName(e.target.value)}
                className="input-field"
            />
            <div className='avatars-and-arrow'>
                <div className="members-icons">
                    {avatars.slice(avatarStartIndex, avatarStartIndex + 4).map((avatar, index) => (
                        <div key={index} className="member-icon">
                            <div className="icon">
                                <img src={avatar} alt={`User ${index + avatarStartIndex + 1}`} />
                            </div>
                            <span className="icon-name">Name {index + avatarStartIndex + 1}</span>
                        </div>
                    ))}
                </div>
                <div className="member-icon arrow-icon" onClick={handleShowMoreAvatars}>
                    <img src={isAtEnd ? reloadArrow : rightArrow} alt={isAtEnd ? "Reload" : "Next"} className="icon-image" />
                </div>
            </div>

            <div className="button-group">
                <button onClick={handleAddFriendOrFamily} className="create-button">Create</button>
                <button onClick={() => {
                    handleAddFriendOrFamily();
                    setMemberName('');
                }} className="add-button">+Add</button>
            </div>
            
            {/* Listing the members */}
            <ul>
                {members.map((member, index) => (
                    <li className="added-list" key={index}>{member}</li>
                ))}
            </ul>
        </div>
    );
};

export default FriendsAndFamilyCategory;

