import React, { useEffect, useRef } from 'react';

const EmbeddedWebView = ({ url }) => {
  const iframeRef = useRef(null);

  const scrollIframeContent = (direction) => {
    if (!iframeRef.current) return;

    const scrollAmount = 100; 
    const contentWindow = iframeRef.current.contentWindow;

    switch (direction) {
      case 'up':
        contentWindow.scrollBy(0, -scrollAmount);
        break;
      case 'down':
        contentWindow.scrollBy(0, scrollAmount);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      switch (e.key) {
        case 'ArrowUp':
          scrollIframeContent('up');
          break;
        case 'ArrowDown':
          scrollIframeContent('down');
          break;
        case 'ArrowLeft':
          scrollIframeContent('left');
          break;
        case 'ArrowRight':
          scrollIframeContent('right');
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <iframe
      ref={iframeRef}
      src={url}
      style={{ width: '100%', height: '100%', border: 'none' }}
      title="Embedded Content"
    ></iframe>
  );
};

export default EmbeddedWebView;
