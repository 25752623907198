import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from 'src/store/user'; 
import '../../assets/stylesheets/account-modal.css';
import profilePicture from '../../assets/images/profile-picture5.png';

function AccountModal(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedImage, setSelectedImage] = useState(profilePicture);

    function handleResetPassword() {
        dispatch(logout()); 
        props.onClose(); 
        navigate('/auth');
    }

    function handleImageChange(e) {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0];
            setSelectedImage(URL.createObjectURL(img));
        }
    }

    if (!props.isVisible) return null;

    return (
        <div className="modal-overlay" onClick={props.onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h5 className="modal-title">Account Settings</h5>
                    {/* <button type="button" className="close-button" onClick={props.onClose}>&times;</button> */}
                </div>
                <div className="modal-body2">
                    <img src={selectedImage} alt="Profile" className="profile-picture" />
                    <div className='buttons'>
                        <input type="file" onChange={handleImageChange} id="imageInput" hidden />
                        {/* <button className="button prim" onClick={() => document.getElementById('imageInput').click()}>Change Picture</button> */}
                        <button className="button sec" onClick={handleResetPassword}>Reset Password</button>
                    </div>
                </div>
                {/* <div className="modal-footer">
                    <button className="button button-primary" onClick={handleSaveChanges}>Save Changes</button>
                </div> */}
            </div>
        </div>
    );
}

export default AccountModal;
