import React, { useState, useEffect } from 'react';
//import { loadStripe } from '@stripe/stripe-js';
import '../assets/stylesheets/subscribe.css';
import { postRequestCustomerPortalSession } from '../assets/requests';

const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL
const stripePublishKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
const stripePricingTableId = process.env.REACT_APP_PRICING_TABLE_ID;

async function handleCustomerPortalSubmit(e) {
  e.preventDefault();
  let stripeId = e.target.childNodes[0].innerHTML;
  console.log(stripeId)
  let config = {
    payload: {
              DOMAIN: FRONTEND_URL,
              CUSTOMER_ID: stripeId
            }
  }
  let response = await postRequestCustomerPortalSession(config)
  if (response.status === 301) {
    window.location = response.url;
  }
  console.log('Response:', response)
}


const Header = () => (
  <header className="stripe-header">
    <h1>Choose Your Preferred Kinstak Plan!</h1>
  </header>
);

const ProductDisplay = (props) => {

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
    document.body.removeChild(script);
    };
    }, []);
    return React.createElement("stripe-pricing-table", {
    "pricing-table-id": stripePricingTableId,
    "publishable-key": stripePublishKey,
    "client-reference-id": props.userId
    });
 }

 const ProductDisplayWithHeader = (props) => {
  return (
    <div className='header-and-pricing'>
      <Header />
      <ProductDisplay userId={props.userId} />
    </div>
  );
};


const ManageDisplay = ({ stripeCustomerId }) => {
  return (
    <section className="sore-thumb">
      <div className="product Box-root">
        <div className="description Box-root">
        <h3>Your Subscription is Up and Running!</h3>
        </div>
      </div>
      <form onSubmit={ handleCustomerPortalSubmit }>
        <div style={{ visibility: 'hidden' }}>{ stripeCustomerId }</div>
        <button className="manage-subs-btn" type="submit">
          Manage your billing information
        </button>
      </form>
    </section>
  );
};


const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

const getSubscriptionInfo = async (username) => {
  let API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL
  let user;
  try {
    if (username) {
      // const res = await api.post('/api/auth/login/', { username, password })
      console.log('getSubscriptionInfo:', username)
      let userRequest = await fetch(`${ API_GATEWAY_URL }/v1/users/${ username }`)
      user = await userRequest.json();
    }
  } catch (e) {
    return e.message;
  }
  return user
}

export default function Subscribe() {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');
  let [userId, setUserId] = useState('');
  let [stripeCustomerId, setStripeCustomerId] = useState(null);

  useEffect(() => {
    const fetchSubscriptionInfo = async () => {
      let userid = localStorage.getItem("username")
      setUserId(userid)
      let user = await getSubscriptionInfo(userId)
      let stripecustomerid = user.subscription.stripeId;
      setStripeCustomerId(stripecustomerid)
    }
    fetchSubscriptionInfo();

  },[userId])

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  console.log(success);

  if (!stripeCustomerId && message === '') {
    return <ProductDisplayWithHeader userId={userId} />;
  } else if ( stripeCustomerId ) {
    return <ManageDisplay stripeCustomerId={stripeCustomerId} sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
}
