import React, { useState, useEffect, useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import NavigationComponent from '../navigationComponent'
import { useLoadScript } from '@react-google-maps/api';
//import * as amplitude from '@amplitude/analytics-browser';

const libraries = ['places']; // Has to be outside of component

const EventsAndLocationsCategory = () => {
    const [eventsAndLocationsTitle, setEventsAndLocationsTitle] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [location, setLocation] = useState('');
    const [eventsAndLocations, setEventsAndLocations] = useState([]);
    const [message, setMessage] = useState('');
    const [userId, setUserId] = useState(null);
    const [categoryCreated, setCategoryCreated] = useState(false);
    const navigate = useNavigate();
    const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL
    const [showUpgradeLink, setShowUpgradeLink] = useState(false);


    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setUserId(user.username);
                //amplitude.track("Category Page Accessed", { category: 'Events and Locations' });

            })
            .catch(err => console.error('Error fetching authenticated user', err));
    }, []);


    const customPostRequest = async (url, data) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorBody = await response.json().catch(() => ({}));
                const errorMessage = errorBody.message || `Server responded with status: ${response.status}`;
                throw new Error(errorMessage);
            }

            return await response.json().catch(e => {
                throw new Error(`Error parsing JSON: ${e.message}`);
            });
        } catch (error) {
            if (error.name === 'TypeError' && error.message.includes('NetworkError')) {
                throw new Error('Network error: Please check your internet connection.');
            }
            console.error('Error in customPostRequest:', error);
            throw error;
        }
    };

    async function fetchUserSubscription(userId) {
        const url = `${API_GATEWAY_URL}/v1/users/${userId}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });
            const data = await response.json();
            console.log("this is the data ", data);

            return {
                categoryCount: data.categoryCount,
                categoryLimit: data.subscription.categoryLimit
            };
        } catch (error) {
            console.error('Error fetching user subscription:', error);
            return null;
        }
    };

    const handleCreate = async () => {
        if (!userId) {
            setMessage('User not authenticated');
            setShowUpgradeLink(false);

            return;
        }
    
        try {
            const subscriptionData = await fetchUserSubscription(userId);
            if (!subscriptionData) {
                setMessage('Failed to fetch subscription data');
                setShowUpgradeLink(true);
                return;
            }
    
            const { categoryCount, categoryLimit } = subscriptionData;
    
        console.log("Subscription Data:", categoryCount +1 , categoryLimit);
            if (categoryCount >= categoryLimit) {
                setMessage('You have reached your category limit.');
                setShowUpgradeLink(true);

                return;
            }
        } catch (error) {
            console.error('Error fetching category count:', error);
            setMessage(`Failed to fetch category count: ${error.message}`);
            setShowUpgradeLink(true);
            return;
        }
    
        const categoryData = {
            userId: userId,
            type: "event",
            subType: "location"
        };
    
        if (eventsAndLocationsTitle.trim()) {
            categoryData.name = eventsAndLocationsTitle;
        }
        if (fromDate.trim()) {
            categoryData.calendarAttrs = { ...categoryData.calendarAttrs, fromDate: fromDate + "T00:00:00Z" };
        }
        if (toDate.trim()) {
            categoryData.calendarAttrs = { ...categoryData.calendarAttrs, toDate: toDate + "T23:59:59Z" };
        }
        if (location.trim()) {
            categoryData.location = { text: location };
        }
    
        if (!categoryData.name && !categoryData.calendarAttrs && !categoryData.location) {
            setMessage('At least one field must be filled out');
            return;
        }
    
        try {
            const apiURL = `${process.env.REACT_APP_API_GATEWAY_URL}/k1/users/${userId}/categories`;
            const responseData = await customPostRequest(apiURL, categoryData);
    
            if (responseData.error) {
                throw new Error(responseData.error);
            }
    
            setEventsAndLocations(prev => [...prev, { eventsAndLocationsTitle, fromDate, toDate, location }]);
            setEventsAndLocationsTitle('');
            setFromDate('');
            setToDate('');
            setLocation('');
            setMessage('Category created successfully!');
        //amplitude.track("Category Created", { categoryName: "Events and Locations", userId: userId });

        setCategoryCreated(true);
    
        } catch (error) {
            console.error('Error in handleCreate:', error);
            setMessage(`Failed to create category: ${error.message}`);
        }
    };


    const handleUploadClick = () => {
        //amplitude.track("Upload Page Visited");
        navigate('/upload');
    };

    //Navigation icons

    function handleHomeClick(){
        navigate('/gallery');
    }

    //Google Maps API
    const [autocomplete, setAutocomplete] = useState(null);
    const locationInputRef = useRef(null);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    useEffect(() => {
        if (isLoaded && !autocomplete) {
            console.log("Initializing Google Places Autocomplete");
            const googleMapsAutocomplete = new window.google.maps.places.Autocomplete(locationInputRef.current, {});
            googleMapsAutocomplete.addListener('place_changed', () => {
                const selectedPlace = googleMapsAutocomplete.getPlace();
                const address = selectedPlace.formatted_address || selectedPlace.name;
                setLocation(address);
            });
            setAutocomplete(googleMapsAutocomplete);
        }
    }, [isLoaded, autocomplete]);


    return (
        <div className="vacation-category">
            <NavigationComponent handleHomeClick={handleHomeClick} />
            <h2 className="category-header">Events & Locations</h2>
            {message && <p className="message">{message}</p>}
            {showUpgradeLink && (
                <p>Please <span className="link-like" onClick={() => navigate('/subscribe')}>upgrade</span> your plan.</p>
            )}
            {message === 'Category created successfully!' && (
                <p><Link to="/gallery">Click here</Link> to return to the main gallery.</p>
            )}
            <input
                type="text"
                placeholder="Events & Locations Title"
                value={eventsAndLocationsTitle}
                onChange={(e) => setEventsAndLocationsTitle(e.target.value)}
                className="input-field"
            />
            <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="input-field"
            />
            <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="input-field"
            />
            <input
                ref={locationInputRef}
                type="text"
                placeholder="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="input-field"
            />
            <div className="button-group">
                <button onClick={handleCreate} className="create-button">Create</button>
            </div>
            {categoryCreated && (
                <div className='list_and_upload'>
                    <ul>
                        {eventsAndLocations.map((event, index) => (
                            <li key={index} className="added-list">
                                {event.eventsAndLocationsTitle} -
                                {event.fromDate && `${event.fromDate} to `}
                                {event.toDate && event.toDate}
                                {event.location && ` at ${event.location}`}
                            </li>
                        ))}
                    </ul>
                        <button className="upload-button-list" onClick={handleUploadClick}>Upload Photos</button>
                     </div>
            )}
        </div>
    );
};

export default EventsAndLocationsCategory;
