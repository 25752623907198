import React, { useState, useEffect} from 'react';
import { Auth } from 'aws-amplify';
import { Link, useNavigate} from 'react-router-dom';
import NavigationComponent from '../navigationComponent'
//import * as amplitude from '@amplitude/analytics-browser';


const WeddingCategory = () => {
    const [weddingName, setWeddingName] = useState('');
    const [weddingDate, setWeddingDate] = useState('');
    const [weddings, setWeddings] = useState([]);
    const [userId, setUserId] = useState(null);
    const [message, setMessage] = useState('');
    const [categoryCreated, setCategoryCreated] = useState(false);
    const navigate = useNavigate();
    const [annual, setAnnual] = useState(false);
    const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL
    const [showUpgradeLink, setShowUpgradeLink] = useState(false);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setUserId(user.username);
                //amplitude.track("Category Page Accessed", { category: 'Wedding' });
            })
            .catch(err => console.error('Error fetching authenticated user', err));
    }, []);

    const validateCategoryData = (data) => {
        // Check for non-empty wedding name
        // if (!data.name || data.name.trim() === '') {
        //     setMessage('Wedding name cannot be empty');
        //     return false;
        // }

        // // Check character limits
        // if (data.name.length > 100) {
        //     setMessage('Wedding name is too long');
        //     return false;
        // }

        // // Date validation
        // const weddingDate = new Date(data.calendarAttrs.fromDate);
        // const currentDate = new Date();
        // if (weddingDate < currentDate) {
        //     setMessage('Wedding date cannot be in the past');
        //     return false;
        // }

        return true;
    };


    const customPostRequest = async (url, data) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorBody = await response.json().catch(() => ({}));
                const errorMessage = errorBody.message || `Server responded with status: ${response.status}`;
                throw new Error(errorMessage);
            }

            return await response.json().catch(e => {
                throw new Error(`Error parsing JSON: ${e.message}`);
            });
        } catch (error) {
            if (error.name === 'TypeError' && error.message.includes('NetworkError')) {
                throw new Error('Network error: Please check your internet connection.');
            }
            console.error('Error in customPostRequest:', error);
            throw error;
        }
    };

    async function fetchUserSubscription(userId) {
        const url = `${API_GATEWAY_URL}/v1/users/${userId}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });
            const data = await response.json();
            return {
                categoryCount: data.categoryCount,
                categoryLimit: data.subscription.categoryLimit
            };
        } catch (error) {
            console.error('Error fetching user subscription:', error);
            return null;
        }
    };

    const handleCreate = async () => {
        if (!userId) {
            setMessage('User not authenticated');
            setShowUpgradeLink(false);
            return;
        }

        if (!weddingName || !weddingDate) {
            setMessage('Please enter both wedding name and date');
            setShowUpgradeLink(false);
            return;
        }
        try {
            const subscriptionData = await fetchUserSubscription(userId);
            if (!subscriptionData) {
                setMessage('Failed to fetch subscription data');
                setShowUpgradeLink(true);
                return;
            }

            const { categoryCount, categoryLimit } = subscriptionData;

            if (categoryCount >= categoryLimit) {
                setMessage('You have reached your category limit.');
                setShowUpgradeLink(true);
                return;
            }
        } catch (error) {
            console.error('Error fetching category count:', error);
            setMessage(`Failed to fetch category count: ${error.message}`);
            setShowUpgradeLink(true);
            return;
        }


        const categoryData = {
            userId: userId,
            name: weddingName,
            type: "event",
            subType: "wedding",
            calendarAttrs: {
                fromDate: weddingDate + "T00:00:00Z",
                toDate: weddingDate + "T23:59:59Z",
                annual: annual
            },

        };

        if (!validateCategoryData(categoryData)) {
            setMessage('Invalid category data');
            console.log(`Invalid category data, ${categoryData}`);
            return;
        }

        try {
            const apiURL = `${process.env.REACT_APP_API_GATEWAY_URL}/k1/users/${userId}/categories`;
            const responseData = await customPostRequest(apiURL, categoryData);

            if (responseData.error) {
                throw new Error(responseData.error);
            }

            setWeddings(prev => [...prev, { weddingName, weddingDate }]);
            setWeddingName('');
            setWeddingDate('');
            setMessage('Category created successfully!');
            //amplitude.track("Category Created", { categoryName: "Wedding", userId: userId });

            setCategoryCreated(true);

        } catch (error) {
            console.error('Error in handleCreate:', error);
            setMessage(`Failed to create category: ${error.message}`);
        }
    };

    const handleUploadClick = () => {
        //amplitude.track("Upload Page Visited");
        navigate('/upload');
    };

    //Navigation icons
    function handleHomeClick(){
        navigate('/gallery');
    }
    //Aniversary checkbox
    const handleAddAnniversaryToggle = () => {
        setAnnual(!annual);
    };
    return (
        <div className="wedding-category">
            <NavigationComponent
                handleHomeClick={handleHomeClick}
            />
            <h2 className="category-header">Wedding</h2>
            {message && <p className="message">{message}</p>}
            {showUpgradeLink && (
            <p>Please <span className="link-like" onClick={() => navigate('/subscribe')}>upgrade</span> your plan.</p>
            )}
            {message === 'Category created successfully!' && (
                <p><Link to="/gallery">Click here</Link> to return to the main gallery.</p>
            )}
            <input
                type="text"
                placeholder="Whose Wedding?"
                value={weddingName}
                onChange={(e) => setWeddingName(e.target.value)}
                className="input-field"
            />
            <input
                type="date"
                value={weddingDate}
                onChange={(e) => setWeddingDate(e.target.value)}
                className="input-field"
            />
            <label className="add-anniversary-button">
                <input
                    type="checkbox"
                    checked={annual}
                    onChange={handleAddAnniversaryToggle}
                    className='add-anniversary-checkbox'
                />
                <p>Add Anniversary Category</p>
            </label>
            <div className="button-group">
                <button onClick={handleCreate} className="create-button">Create</button>
            </div>
            {categoryCreated && (
                <div className='list_and_upload'>
                    <ul>
                        {weddings.map((wedding, index) => (
                            <li className="added-list" key={index}>{wedding.weddingName} - {wedding.weddingDate}</li>
                        ))}
                    </ul>
                    <button className="upload-button-list" onClick={handleUploadClick}>Upload Photos</button>
                </div>
            )}

        </div>
    );
};

export default WeddingCategory;
