import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useDispatch } from 'react-redux';
import { fetchFavorites, fetchAssetsByCategory, postRequestSignedUrls } from '../../assets/requests'; 
import placeholderImg from "../../assets/images/camera.jpg";
import moment from 'moment';
import "../../assets/stylesheets/gallery-style.css";
import "../../assets/stylesheets/assets.css";
import "../../assets/stylesheets/duplicates.css"; 
import "../../assets/stylesheets/modal.css";
import { handleModalOpen } from "../../components/Gallery/modals";
import { ModalHtml } from "../../components/Gallery/modals";
import upArrow from '../../assets/images/up-arrow.png';
import backArrow from '../../assets/images/back-arrow3.png';
import homeIcon from '../../assets/images/home-icon.png'

const FavoritesCategory = () => {
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [modalActive, setModalActive] = useState(false); 
  const [currentAssetId, setCurrentAssetId] = useState(null); 
  const [assets, setAssets] = useState({});
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const dispatch = useDispatch();
  const userId = localStorage.getItem("username");
  const navigate = useNavigate(); 

  const signAssets = useCallback(async (assets) => {
    let assetIds = Object.keys(assets);
    let thumbnailKeys = [];
    let s3Uris = [];

    assetIds.forEach(id => {
      if (assets[id].thumbnails && assets[id].thumbnails.length > 0) {
        let parsedKey = assets[id].thumbnails[1].split('/');
        let slicedKey = parsedKey.slice(4);
        slicedKey.unshift('thumb');
        thumbnailKeys.push(slicedKey.join('/'));
      }

      let s3Uri = assets[id].s3uri.split('/').slice(-1);
      s3Uri.unshift('full');
      s3Uris.push(s3Uri.join('/'));
    });

    let signedThumbnailUrls = await postRequestSignedUrls({ userId, payload: thumbnailKeys });
    let signedS3Uris = await postRequestSignedUrls({ userId, payload: s3Uris });

    assetIds.forEach((id, idx) => {
      if (signedThumbnailUrls[idx]) {
        assets[id].src = signedThumbnailUrls[idx];
      }
      if (signedS3Uris[idx]) {
        assets[id].s3uri = signedS3Uris[idx];
      }
    });

    return assets;
  }, [userId]); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const details = await fetchFavorites(); 
        setCategoryDetails(details);
        if (details) {
          const rawAssets = await fetchAssetsByCategory({
            categoryId: details.id,
            userId: userId
          });
          const signedAssets = await signAssets(rawAssets);
          setAssets(signedAssets);
        }
      } catch (error) {
        console.error("Error fetching assets: ", error);
      }
    };

    fetchData();
  }, [userId, signAssets, refreshTrigger]);

  if (!categoryDetails || !Object.keys(assets).length) {
    return <div>Loading...</div>;
  }

  const closeModal = () => {
    setModalActive(false);
  };

  const goBack = () => {
    navigate(-1); 
  };

  const handleHomeClick = () => navigate('/gallery');
  const handleBackToTop = () => window.scrollTo(0, 0);

  const handleAssetDeleted = (deletedAssetId) => {
    const updatedAssets = { ...assets };
    delete updatedAssets[deletedAssetId];
    setAssets(updatedAssets);
    closeModal();
    setRefreshTrigger(!refreshTrigger);

  };
  return (
    <div className="duplicates-category-container">
      <h2 className="row-title-duplicates">Favorites</h2>
  
      <div className="go-back">
        <img className='nav-icon-home home fav' alt="Go to base of navigation" src={homeIcon} onClick={handleHomeClick} />
        <img className='nav-icon-back back' alt="Go back one level." src={backArrow} onClick={goBack} />
        <img className='nav-icon-top top' alt="Go back to top." src={upArrow} onClick={handleBackToTop} />
      </div>
  
      <div className="gallery-container">
      {Object.keys(assets)
        .sort((a, b) => {
          const dateA = assets[a].content?.date ? moment(assets[a].content.date, "YYYY-MM-DDTHH:mm:ssZ").toDate() : new Date(0); 
          const dateB = assets[b].content?.date ? moment(assets[b].content.date, "YYYY-MM-DDTHH:mm:ssZ").toDate() : new Date(0);
          return dateA - dateB;
        })
        .map((assetId) => {
          const asset = assets[assetId];
          return (
            <div key={asset.id} className="gallery-thumb-duplicates">
              <div
                className="gallery-thumb2"
                style={{ backgroundImage: `url('${asset.src || placeholderImg}')` }}
                onClick={() => {
                  setCurrentAssetId(asset.id);
                  const modalProps = {
                    dispatch: dispatch,
                    state: {
                      modalActive: !modalActive,
                      publishedAssets: assets,
                      modalStatus: 'Image',
                    },
                    payload: {
                      assetId: asset.id,
                    }
                  };

                  handleModalOpen(modalProps);
                  setModalActive(true);
                }}
              >
                <div className="duplicates-date">
                  {asset.content?.date ? moment(asset.content.date).format('DD MMMM YYYY') : 'Date not available'}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {modalActive && <ModalHtml state={{ modalActive, currentAsset: currentAssetId, modalStatus: "Image", assets }} onAssetDeleted={handleAssetDeleted} dispatch={dispatch} onClose={closeModal} />}
    </div>
  );
};

export default FavoritesCategory;
