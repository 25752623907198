import React, { useState } from 'react';
import "../assets/stylesheets/kategories.css"

const Kategories = (props) => {
    const [currentStep, setCurrentStep ] = useState('start')
    const [selectedType, setSelectedType ] = useState('')
    const [categories, setCategories ] = useState(['Birthday', 'Wedding', 'College', 'Vacation'])
    console.log(setCategories)

    const PrimaryBtn = (props) => {
        let copy = props.text;
        let handleFn = props.onclick;
        let className = props.className;
        //let class = props.class || 'primary-btn';

        return <>
        <div className="primary-btn-container">
            <div className={ className || "primary-btn" } onClick={ handleFn }>
                { copy }
            </div>
        </div>
        </>
    }

    const handleStart = () => {
        setCurrentStep(selectedType)
    }

    const handleCancel = () => {
        setCurrentStep('start')
    }

    const handleSelectedType = (e, cat) => {
        //console.log('e:', e)
        let prev = document.querySelector('.active');
        prev?.classList.remove('active')
        let target = e.target;
        target.classList.add('active')
        setSelectedType(cat)
        console.log('Selected Type:', cat)
    }

    const handleByo = () => {

    }

    const handleCreateBirthday = () => {

    }

    const selectInputs = () => {
        return <>
        <ul className="select">
            { categories.map((category, idx) => {
                return <li onClick={ (e) => handleSelectedType(e, category) } >{ category }</li>
            })}
        </ul>
        </>
    }

    const birthdayInputs = () => {
        return <>
        <div className="birthday-container">
        <input className="input-name" type="text" placeholder="Name"></input>
        <input className="input-date" type="date" placeholder='Date'></input>
        </div>
        </>
    }
    
    const stepsConfig = (props) => {
        let config = {
            start: {
                copy: "Select an event type.",
                btnCopy: "CONTINUE",
                inputs: selectInputs(),
                cb: handleStart
            },
            cancel: {
                copy: '',
                btnCopy: "CANCEL",
                className: 'cancel-btn',
                inputs: null,
                cb: handleCancel
            },
            byo: {
                copy: "",
                btnCopy: "BUILD YOUR OWN",
                className: 'secondary-btn',
                cb: handleByo
            },
            Birthday: {
                copy: "Birthday copy",
                btnCopy: "Confirm",
                inputs: birthdayInputs(),
                cb: handleCreateBirthday
            }
        }

        return config[props]
    }

    return <>
    <div className="header-buffer">
    <h2>Kin-Category Creator</h2>

          </div>
          <div className="center-container">
            <div className='center-content'>
              <h1>Create a Kin-Category</h1>
                <br/>
                <div className="message">
                { stepsConfig(currentStep).copy }
                </div>
                <div className="inputs">
                    { stepsConfig(currentStep).inputs }
                </div>
                    <PrimaryBtn text={ stepsConfig(currentStep).btnCopy } onclick={ stepsConfig(currentStep).cb } />

                    { currentStep === 'start' &&
                    <>
                        <div className='or'>OR</div> 
                        <PrimaryBtn className={ stepsConfig('byo').className } text={ stepsConfig('byo').btnCopy } onclick={ stepsConfig('byo').cb } /> 
                    </>

                    }
                    

                    { currentStep !== 'start' && 
                    <>
                    <div className="show-cancel">
                        <PrimaryBtn className={ stepsConfig('cancel').className } text={ stepsConfig('cancel').btnCopy } onclick={ stepsConfig('cancel').cb } />
                    </div> 
                    </>
                    }
            </div>
          </div>
    </>
}

export default Kategories
