import React, { useState, useEffect, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { logout } from 'src/store/user';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../../assets/stylesheets/header-style.css';
import '../../assets/stylesheets/shepherd.css';
import logo from '../../assets/images/kinstak-logo01.jpg';
import menuIcon from  '../../assets/images/menu.png'
//import userIcon from '../../assets/images/user-icon.png'
//import magnifyingGlass from '../../assets/images/magnifying-glass.png'
import { Auth } from 'aws-amplify';
import { login } from '../../store/user'
import profilePicture from '../../assets/images/profile-picture5.png'
import AccountModal from './accountModal'
import { isNotLGSmartTV } from '../../assets/lg';
import { Message } from 'semantic-ui-react';
import BackToLGModal from './backToLgModal';
//import * as amplitude from '@amplitude/analytics-browser';
import { ShepherdTourContext } from 'react-shepherd';

const loggedOutHeader = (props) =>
  <div className="header">
    <div className="header-logo-container">
        <a href="/gallery"><img id="logo-image" src={ logo } className="fluid-image" alt="Kinstak logo." /></a>
    </div>
    <div className="desktop-home-nav-container">
        {/* <button id="home-button" className="button-med white shadow01 primary-font" onClick={ (e) => props.handleNavigation(e, '/')}>home</button> */}
        <button id="signup-login-button" className="button-med primary" onClick={ (e) => props.handleNavigation(e, '/auth')}>signup / login</button>

    </div>
    <div className={ `mobile-signin-pane ${ props.isSignInHamburgerMenuVisible ? 'active' : '' }` }>
      <ul id="signin-menu" className="shadow04">
            {/* <li className="primary-font bold mobile-top-level-link01" onClick={ (e) => props.handleNavigation(e, '/')}>home</li>  */}
            <li className="primary-font bold mobile-top-level-link01" onClick={ (e) => props.handleSignInNavigation(e, '/auth')}>signup / login</li>
      </ul>
      </div>

    <img src={ menuIcon } id="mobile-menu-icon-home" className="fluid-image" alt="Hamburger navigation menu." onClick={ (e) => props.handleSignInHamburger(e)}/>
  </div>

const NotificationPopup = ({ showNotification, welcomeMessage, setShowNotification }) => (
  <li className={`notification-popup2 ${showNotification ? 'show' : 'hide'}`}>
    {welcomeMessage}
    <span className="close-btn2" onClick={() => setShowNotification(false)}>
      &times;
    </span>
  </li>
);


  const loggedInHeader = (props) =>
  <div className="header-popup">
    <div className="header">
      <img src={ menuIcon } id="mobile-menu-icon" className="fluid-image" alt="Hamburger navigation menu." onClick={ (e) => props.handleHamburger(e)} />
      <div className="header-logo-container" onClick={ (e) => props.handleNavigation(e, '/gallery')}>
        <img src={logo} className="fluid-image kinstak-logo-left" alt="Kinstak logo." />
      </div>
      <div className="header-content-container">
          <form action="" className="nav-search-form">
              {/* <input type="search" name="" id="nav-search-bar" />
              <button type="submit" onClick={ props.handleQuerySubmit } id="nav-search-submit">
                  <img src={ magnifyingGlass } className="nav-search-mag" alt="" />
              </button> */}
          </form>
          <div className="memory-use-display">
              {/* <span>1.57 GB USED</span>  */}
          </div>
          <div className="desktop-nav-container" >
            <div className="view-files-dropdown">
              <button id="view-files-button" className="button-med white shadow01" onClick={ () => props.toggleViewFilesMenuVisibility(props) }>view gallery</button>
              <div className={ `view-files-nav-pane ${ props.isViewFilesMenuVisible ? 'active' : '' }` }>
                <ul id="view-files-nav" className="shadow04 dropdown-menu">
                    {/* <li className="desktop-nav-link01" onClick={ (e) => props.handleNavigation(e, '/gallery')}>all</li>  */}
                    <li className="desktop-nav-link01" onClick={ (e) => props.handleNavigation(e, '/gallery')}>photos</li>
                    <li className="desktop-nav-link01" onClick={(e) => props.handleDuplicatesClick(e)}>duplicates</li>
                    <li className="desktop-nav-link01" onClick={(e) => props.handleFavoritesClick(e)}>favorites</li>
                </ul>
              </div>
            </div>
            <div className="create-category-dropdown">
              <button id="create-kin-cat-button" className="button-med primary create-kin" onClick={ () => props.toggleCreateKinCategoryMenuVisibility(props) }>create-a-kin-category</button>
              <div className={ `create-a-kin-category-pane ${ props.isCreateKinCategoryMenuVisible ? 'active' : '' }` }>
                <ul id="create-a-kin-category-nav" className="shadow04">
                    <li className="desktop-nav-link01" onClick={ (e) => props.handleKinNavigation(e, 'Birthday', props.navigate) }>Birthday</li>
                    <li className="desktop-nav-link01" onClick={ (e) => props.handleKinNavigation(e, 'Wedding', props.navigate) }>Wedding</li>
                    <li className="desktop-nav-link01" onClick={ (e) => props.handleKinNavigation(e, 'Events & Locations', props.navigate) }>Events & Locations</li>

                    {/* <li className="desktop-nav-link01" onClick={ (e) => props.handleKinNavigation(e, 'College', props.navigate) }>College</li> */}
                    {/* <li className="desktop-nav-link01" onClick={ (e) => props.handleKinNavigation(e, 'Vacation', props.navigate) }>Vacation</li> */}

                    <li className="desktop-nav-link01 action-nav-link" onClick={(e) => props.handleBuildYourOwnClick()}>
                        Build Your Own
                        <ul className={`sub-nav ${props.isSubNavVisible ? 'active' : ''}`}>
                            {/* <li className="desktop-nav-link03 coming-soon " onClick={ (e) => props.handleKinNavigation(e, 'Friends & Family', props.navigate) }>Friends & Family</li>
                            <li className="desktop-nav-link03 coming-soon" onClick={ (e) => props.handleKinNavigation(e, 'Pets', props.navigate) }>Pets</li>
                            <li className="desktop-nav-link03 coming-soon" onClick={ (e) => props.handleKinNavigation(e, 'Events & Locations', props.navigate) }>Events & Locations</li>
                            <li className="desktop-nav-link03 coming-soon" onClick={ (e) => props.handleKinNavigation(e, 'Custom Category', props.navigate) }>Custom Category</li> */}
                            <li className="desktop-nav-link03 coming-soon " >Friends & Family</li>
                            <li className="desktop-nav-link03 coming-soon" >Pets</li>
                            {/* <li className="desktop-nav-link03 coming-soon" >Events & Locations</li> */}
                            <li className="desktop-nav-link03 coming-soon" >Custom Category</li>
                        </ul>
                    </li>
                </ul>
              </div>
            </div>
              <button id="upload-button" className="button-med secondary" onClick={ () => props.handleUploadClick() }>upload</button>
              { /* <!-- <button onClick={ props.tour.start }>Start Tour</button> */ }
          </div>
          <div className="header-account-display" id="desktop-account-display">
              <img src={ profilePicture } className="fluid-image profile-icon-right" alt="User Avatar" onClick={ () => props.toggleAccountMenuVisibility() } />
          </div>
      </div>
      <div className={ `mobile-nav-pane ${ props.isMobileTopLevelMenuVisible ? 'active' : '' }` }>
        <ul id="top-level-menu" className="shadow04">
            <li className="primary-font bold mobile-top-level-link01" onClick={ () => props.handleMainCategoryClick('viewFiles') }>View Gallery</li>
            {
                props.activeMainCategory === 'viewFiles' &&
                <>
                    <li className="mobile-top-level-link01 sub-category"
                        onClick={ (e) => props.handleNavigation(e, '/gallery')}>photos</li>
                    <li className="mobile-top-level-link01 sub-category"
                        onClick={ (e) => props.handleDuplicatesClick(e, '/gallery')}>duplicates</li>
                    <li className="mobile-top-level-link01 action-nav-link sub-category"
                        onClick={ (e) => props.handleFavoritesClick(e, '/gallery')}>favorites</li>
                </>
            }

            <li className="primary-font bold mobile-top-level-link01" onClick={ () => props.handleMainCategoryClick('kinCategories') }>Create-A-Kin-Categories</li>
            {
                props.activeMainCategory === 'kinCategories' &&
                <>
                    <li className="mobile-top-level-link01"
                        onClick={ (e) => props.handleKinNavigation(e, 'Birthday', props.navigate) }>Birthday</li>
                    <li className="mobile-top-level-link01"
                        onClick={ (e) => props.handleKinNavigation(e, 'Wedding', props.navigate) }>Wedding</li>
                    <li className="mobile-top-level-link01"
                        onClick={ (e) => props.handleKinNavigation(e, 'Events & Locations', props.navigate) }>Events And Locations</li>
                    {/* <li className="mobile-top-level-link01"
                        onClick={ (e) => props.handleKinNavigation(e, 'Vacation', props.navigate) }>Vacation</li> */}
                </>
            }
            <li className="primary-font bold mobile-top-level-link01" onClick={ () => props.handleMainCategoryClick('buildYourOwn')}>Build Your Own</li>
                  {
                      props.activeMainCategory === 'buildYourOwn' &&
                      <>
                      <li className="mobile-top-level-link01 sub-category"
                            >Coming Soon * </li>
                        {/* <li className="mobile-top-level-link01 sub-category"
                            onClick={ (e) => props.handleKinNavigation(e, 'Friends & Family', props.navigate) }>Friends & Family</li>
                        <li className="mobile-top-level-link01 sub-category"
                            onClick={ (e) => props.handleKinNavigation(e, 'Pets', props.navigate) }>Pets</li>
                        <li className="mobile-top-level-link01 sub-category"
                            onClick={ (e) => props.handleKinNavigation(e, 'Events & Locations', props.navigate) }>Events & Locations</li>
                        <li className="mobile-top-level-link01 sub-category"
                            onClick={ (e) => props.handleKinNavigation(e, 'Custom Category', props.navigate) }>Custom Category</li> */}
                    </>
                  }

            <li className="mobile-top-level-link01 secondary" onClick={ () => props.handleUploadClick() }>Upload</li>
        </ul>
      </div>
        <ul>
        {/* Other list items */}
        {/* Other list items */}
      </ul>
    </div>
    <NotificationPopup showNotification={props.showNotification} welcomeMessage={props.welcomeMessage} setShowNotification={props.setShowNotification}/>
</div>

    const accountMenu = (props) => {
      return (
        <div className={`desktop-account-pane ${props.isAccountMenuVisible ? 'active' : ''}`}>
          <ul id="view-account-nav" className="shadow04">
            <li className="desktop-account-link01" onClick={props.toggleAccountModal}>View Account</li>
            <li className='desktop-account-link01 subscribe' onClick={props.handleSubscribe}>Subscription</li>
            <li className="desktop-account-link01" onClick={props.handleLogout}>Sign Out</li>
            {isNotLGSmartTV() && ( // Only show the button when on an LG Smart TV
            <li className="exit-to-lg-btn" onClick={props.handleExitToLG}>Exit to LG</li>
            )}
          </ul>
        </div>
      );
    }

//////////////////////////////////////////////////// MAIN HEADER COMPONENT //////////////////////////////////////////////////////

function Header(props) {
  const tour = useContext(ShepherdTourContext)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //const [navOpaque, setNavOpaque] = useState(false)
  const { user } = useSelector(state => state.user)

  //const [ user, setUser ] = useState(null)
  const [isViewFilesMenuVisible, setIsViewFilesMenuVisible] = useState(false)
  const [isHamburgerMenuVisible, setIsHamburgerMenuVisible] = useState(false)
  const [isAccountMenuVisible, setIsAccountMenuVisible] = useState(false)
  const [ currentHeaderClick, setCurrentHeaderClick ] = useState('')
  const [ isMobileTopLevelMenuVisible, setIsMobileTopLevelMenuVisible ] = useState(false);
  const [ isSignInHamburgerMenuVisible, setIsSignInHamburgerMenuVisible ] = useState(false);
  const [isCreateKinCategoryMenuVisible, setIsCreateKinCategoryMenuVisible] = useState(false);
  const [activeMainCategory, setActiveMainCategory] = useState(null);
  const [isSubNavVisible, setIsSubNavVisible] = useState(false);
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isLGModalOpen, setLGModalOpen] = useState(false);

  const [viewFilesTimeoutId, setViewFilesTimeoutId] = useState(null);
  const [kinCategoriesTimeoutId, setKinCategoriesTimeoutId] = useState(null);
  const [uploadTimeoutId, setUploadTimeoutId] = useState(null);
  const [accountTimeoutId, setAccountTimeoutId] = useState(null);
  const [buildYourOwnTimeoutId, setBuildYourOwnTimeoutId] = useState(null);

  useEffect(() => {
    if (location.pathname === '/upload') {
      setShowNotification(true);
    } else {
      setShowNotification(false);
    }
  }, [location]);

  useEffect(() => {
    setIsViewFilesMenuVisible(false)
    setIsAccountMenuVisible(false)
    Auth.currentAuthenticatedUser().then( data => {
      if (data.username) {
        localStorage.setItem('idToken', data.signInUserSession.accessToken.jwtToken)
        localStorage.setItem('username', data.username)
        dispatch(login({ username: data.username, idToken: data.signInUserSession.accessToken.jwtToken }))}
      }).catch(err => {
        console.log('Error retrieving auth user:', err)
      })
  }, [])

  useEffect(()=> {
    switch (currentHeaderClick) {
      case 'selectedAccount':
        // close view files
        setIsViewFilesMenuVisible(false)
        setIsMobileTopLevelMenuVisible(false)
        setIsCreateKinCategoryMenuVisible(false)
        break;
      case 'selectedViewFiles':
        // close account
        setIsAccountMenuVisible(false)
        setIsCreateKinCategoryMenuVisible(false)
        break;
      case 'selectedViewFilesDropdown':
        // close view files
        setIsViewFilesMenuVisible(false)
        setIsCreateKinCategoryMenuVisible(false)
        // close account
        setIsAccountMenuVisible(false)
        break;
      case 'selectedCreateKinCategory':
        // close view files
        setIsViewFilesMenuVisible(false)
        //setIsCreateKinCategoryMenuVisible(false)
        // close account
        setIsAccountMenuVisible(false)
        break;
      case 'selectedCreateKinCategoryDropdown':
        // close view files
        setIsViewFilesMenuVisible(false)
        setIsCreateKinCategoryMenuVisible(false)
        // close account
        setIsAccountMenuVisible(false)
        break;
      case 'selectedMobileTopLevel':
        setIsAccountMenuVisible(false)
        break;
      case 'selectedViewFilesDropdown':
        setIsMobileTopLevelMenuVisible(false)
      break;
      case 'selectedSignIn':
        setIsSignInHamburgerMenuVisible(false)
        break;
      case 'closeAll':
        setIsAccountMenuVisible(false)
        setIsViewFilesMenuVisible(false)
        setIsMobileTopLevelMenuVisible(false)
        setIsCreateKinCategoryMenuVisible(false)
        break;
      default:
    break;
    }
  }, [currentHeaderClick])

  const logOutFn = () => {
    dispatch(logout());
  }

  const toggleCreateKinCategoryMenuVisibility = () => {
    clearTimeout(viewFilesTimeoutId);
    clearTimeout(uploadTimeoutId);
    clearTimeout(accountTimeoutId);
    clearTimeout(buildYourOwnTimeoutId);


    if (!isCreateKinCategoryMenuVisible) {
      const timeoutId = setTimeout(() => {
        setIsCreateKinCategoryMenuVisible(false);
      }, 5000);
      setKinCategoriesTimeoutId(timeoutId);
    } else {
      clearTimeout(kinCategoriesTimeoutId);
    }

    setIsCreateKinCategoryMenuVisible(!isCreateKinCategoryMenuVisible);
    setCurrentHeaderClick('selectedCreateKinCategory');
  };



  const toggleAccountMenuVisibility = () => {
    clearTimeout(viewFilesTimeoutId);
    clearTimeout(uploadTimeoutId);
    clearTimeout(kinCategoriesTimeoutId);
    clearTimeout(buildYourOwnTimeoutId);

    if (!isAccountMenuVisible) {
      const timeoutId = setTimeout(() => {
        setIsAccountMenuVisible(false);
      }, 5000);
      setAccountTimeoutId(timeoutId);
    } else {
      clearTimeout(accountTimeoutId);
    }

    setIsAccountMenuVisible(!isAccountMenuVisible);
    setCurrentHeaderClick('selectedAccount');
  };


  const toggleViewFilesMenuVisibility = () => {
    clearTimeout(kinCategoriesTimeoutId);
    clearTimeout(uploadTimeoutId);
    clearTimeout(accountTimeoutId);

    if (!isViewFilesMenuVisible) {
      const timeoutId = setTimeout(() => {
        setIsViewFilesMenuVisible(false);
      }, 5000);
      setViewFilesTimeoutId(timeoutId);
    } else {
      clearTimeout(viewFilesTimeoutId);
    }

    setIsViewFilesMenuVisible(!isViewFilesMenuVisible)
    setCurrentHeaderClick('selectedViewFiles')
  }



  const handleLogout = () => {
    console.log("logging out #########")
    logOutFn()
    navigate('/auth')
  }

  const handleUploadClick = () => {
    //amplitude.track("Upload Page Visited");

    navigate('/upload')
    setCurrentHeaderClick('closeAll')
    setShowNotification(true)
  }

  const handleSignInNavigation = (e, target) => {
    e.stopPropagation(); // USED HERE!
    e.preventDefault();
    //toggleMenuVisibility();
    toggleAccountMenuVisibility();
    toggleCreateKinCategoryMenuVisibility();
    setCurrentHeaderClick('selectedSignIn')
    navigate(target)
  }

  const handleNavigation = (e, target) => {
    e.stopPropagation(); // USED HERE!
    e.preventDefault();
    //toggleMenuVisibility();
    // toggleAccountMenuVisibility();
    // toggleCreateKinCategoryMenuVisibility();
    setCurrentHeaderClick('closeAll')
    navigate(target)
  }

  const handleKinNavigation = (e, category, navigate) => {
    e.stopPropagation();
    //amplitude.track("Category Clicked", { category: category });

    setIsCreateKinCategoryMenuVisible(false);
    setCurrentHeaderClick('closeAll')

    if(category === "Build Your Own") {
        setIsSubNavVisible(isSubNavVisible);
    } else {
        setIsSubNavVisible(false);
        const categoryMap = {
          "Birthday": '/birthday-category',
          "College": '/college-category',
          "Vacation": '/vacation-category',
          "Wedding": '/wedding-category',
          "Friends & Family": '/friends-family-category',
          "Pets": '/pets-category',
          "Events & Locations": '/events-locations-category',
          "Custom Category": '/custom-category'
      };
  if (categoryMap[category]) {
      navigate(categoryMap[category]);
  }
}
  }

  const handleHamburger = (e, target) => {
    e.stopPropagation(); // USED HERE!
    e.preventDefault();
    toggleHamburgerMenuVisibility();
  }

  const handleSignInHamburger = (e, target) => {
    e.stopPropagation(); // USED HERE!
    e.preventDefault();
    toggleSignInHamburgerMenuVisibility();
  }

  const toggleHamburgerMenuVisibility = () => {
    setIsHamburgerMenuVisible(!isHamburgerMenuVisible)
    setIsMobileTopLevelMenuVisible(!isMobileTopLevelMenuVisible)
    setCurrentHeaderClick('selectedMobileTopLevel')
  }

  const toggleSignInHamburgerMenuVisibility = () => {
    setIsSignInHamburgerMenuVisible(!isSignInHamburgerMenuVisible)
    //setIsMobileTopLevelMenuVisible(!isMobileTopLevelMenuVisible)
    setCurrentHeaderClick('')
  }

  const handleQuerySubmit = (event) => {
    event.preventDefault();
  }

  const handleKategoriesClick = (event) => {
    navigate('/kin-categories')
    setCurrentHeaderClick('closeAll')
  }

  const handleMainCategoryClick = (categoryName) => {
    if (activeMainCategory === categoryName) {
        setActiveMainCategory(null);
    } else {
        setActiveMainCategory(categoryName);
    }
  }

  const toggleAccountModal = () => {
    setIsAccountModalVisible(!isAccountModalVisible);
    if (isAccountMenuVisible) {
        setIsAccountMenuVisible(false);
    }
  };

  const handleExitToLG = () => {
    setLGModalOpen(!isLGModalOpen);
    console.log('togglling lg tv')
    if (isAccountMenuVisible) {
      setIsAccountMenuVisible(false);
    }
  }

  const handleSubscribe = () => {
    navigate("/subscribe")
    setCurrentHeaderClick('closeAll')

  }
  useEffect(() => {
    return () => {
      clearTimeout(viewFilesTimeoutId);
      clearTimeout(kinCategoriesTimeoutId);
      clearTimeout(uploadTimeoutId);
      clearTimeout(accountTimeoutId);
      clearTimeout(buildYourOwnTimeoutId);
    };
  }, [viewFilesTimeoutId, kinCategoriesTimeoutId, uploadTimeoutId, accountTimeoutId, buildYourOwnTimeoutId]);

  const handleLogoClick = () => {
    navigate('/gallery');
  };

  const handleDuplicatesClick = (e) => {
    navigate("/duplicates");
    setCurrentHeaderClick('closeAll')

  }

  const handleFavoritesClick = () => {
    navigate("/favorites");
    setCurrentHeaderClick('closeAll')
  }
const handleBuildYourOwnClick = () => {
  setIsSubNavVisible(!isSubNavVisible);
};

  const welcomeMessage = isNotLGSmartTV() ? (
    <Message info compact floating className="lg-tv-message">
      Welcome to Kinstak! Embark on your photo journey with ease. <br />
      Simply log in to your new Kinstak account from any device that houses your photos—be it a phone, tablet, or computer.
    </Message>
  ) : (
    <Message info compact floating>
      Welcome to Kinstak! Let's start your journey by uploading some photos.
    </Message>
  );

  return (
    <>
    {user && accountMenu({ handleSubscribe, handleLogoClick, handleExitToLG, setLGModalOpen, isAccountMenuVisible, handleNavigation, handleLogout, setIsAccountModalVisible, toggleAccountModal })}
    { user && loggedInHeader({handleFavoritesClick, handleDuplicatesClick, handleBuildYourOwnClick, handleSubscribe, handleLogoClick, handleExitToLG, welcomeMessage, showNotification, setShowNotification, isSubNavVisible,activeMainCategory, handleMainCategoryClick, isMobileTopLevelMenuVisible, isCreateKinCategoryMenuVisible, toggleViewFilesMenuVisibility, toggleCreateKinCategoryMenuVisibility, handleKategoriesClick, handleUploadClick, toggleAccountMenuVisibility, isViewFilesMenuVisible, handleQuerySubmit, handleLogout, handleNavigation, handleKinNavigation,handleHamburger, isHamburgerMenuVisible, navigate: navigate, tour })}
    { !user && loggedOutHeader({ handleSignInNavigation, isSignInHamburgerMenuVisible, handleSignInHamburger, handleNavigation, handleHamburger, isHamburgerMenuVisible}) }
    {isAccountModalVisible && <AccountModal isVisible={isAccountModalVisible} onClose={toggleAccountModal} />}
    {isLGModalOpen && <BackToLGModal isVisible={isLGModalOpen} onClose={handleExitToLG} />}
    {showNotification && <NotificationPopup showNotification={showNotification} welcomeMessage={props.welcomeMessage} setShowNotification={setShowNotification}/>}

 </>
    /*
    <input className="tmp-login" type='button' onClick={ () => toggleLogin() } value={ user ? 'Logout' : 'Login' } />
    */
  );
}

export default Header;
