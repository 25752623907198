import React  from 'react';
//import { Message } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { useSelector } from 'react-redux';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import "../assets/stylesheets/upload-style.css"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { isNotLGSmartTV } from '../assets/lg';
import LGVideo from '../assets/videos/LG_Video.mp4';
//import Dropbox from '@uppy/dropbox';
//import Instagram from '@uppy/instagram';
//import GoogleDrive from '@uppy/google-drive';
//import Facebook from '@uppy/facebook';
import Dropbox from '@uppy/dropbox'
//import NavigationComponent from '../pages/navigationComponent'
//import * as amplitude from '@amplitude/analytics-browser';


const Upload = () => {
  const navigate = useNavigate()
  const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL
  //const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL
  const isLGTV = isNotLGSmartTV()
  const userId = useSelector((state) => state.user.user.username)
  const getModifiedName = (currentFilename) => {
    let cf = currentFilename.replace(' ', '_').replace('+','_').replace(',','_')
    //cf = userId + '/' + cf
    console.log('Current Filename: ', cf)
    return cf
  }

  const onBeforeUpload = (files) => {
    var updatedFiles = {};

    for (const [key, file] of Object.entries(files)) {
      if (!file.meta.userId) {
          file.meta.userId = userId;
          updatedFiles[key] = {
            ...files[key],
              meta: {
                ...files[key].meta,
                userId: userId
              }
          }
        }
    }
    return updatedFiles;
  }

  const createMultipartUpload = async (file) => {
      const url = `${ API_GATEWAY_URL }/s3/multipart`
      const filename = getModifiedName(file.name)
      console.log('Before: ', url)
      console.log('File: ', file)
      console.log('filename: ', filename)
      const response = await axios.post(url, { filename: filename, type: file.type, metadata: { userId: userId, name: filename, type: file.type}})
      console.log('Response: ', response)

      return { uploadId: response.data.uploadId, key: response.data.key }
 };



  const uppy = new Uppy({
    id: 'uppy-core',
    debug: true,
    autoProceed: false,
    restrictions: {
      maxFileSize: 1000000000,
      maxNumberOfFiles: 10000,
      minNumberOfFiles: null,
      allowedFileTypes: null
    },
    plugins: ['Dropbox'],
    onBeforeUpload: onBeforeUpload
  });
  // Facebook removed from here

  const initializePlugin = (plugin, options, name) => {
    try {
      uppy.use(plugin, options);
     // console.log(`${name} plugin initialized successfully.`);
    } catch (error) {
      console.error(`Error initializing ${name} plugin:`, error);
    }
  };

    initializePlugin(AwsS3Multipart, {
      limit: 4,
      companionUrl: API_GATEWAY_URL,
      createMultipartUpload: createMultipartUpload
    }, 'AwsS3Multipart');

    initializePlugin(Dropbox, {
      companionUrl: API_GATEWAY_URL,
      companionHeaders: {
        "access-control-request-headers": "content-type, accept, uppy-auth-token"
      }
    }, 'Dropbox');

/*     initializePlugin(Instagram, {
      companionUrl: API_GATEWAY_URL
    }, 'Instagram'); */

    // initializePlugin(GoogleDrive, {
    //   companionUrl: 'http://localhost:3020'
    // }, 'GoogleDrive');
/*     initializePlugin(Facebook, {
      companionUrl: API_GATEWAY_URL,
      companionHeaders: {
        "access-control-request-headers": "content-type, accept, uppy-auth-token"
      }
    }, 'Facebook'); */



  uppy.on('complete', result => {
    console.log('successful files:', result.successful)
    if (result.successful.length > 0) {
      //amplitude.track("Upload Success", { fileCount: result.successful.length });
      navigate('/gallery')
    }
    console.log('failed files:', result.failed)
  })

  const handleBackToGallery = () => {
    navigate('/gallery');
  };



  return (
    <>
      <div className="notification-wrapper">
        {/* Conditionally render the video if not on LG Smart TV */}
        {isLGTV && (
          <div className='lg-video'>
            <video width="100%" height="auto" autoPlay controls loop>
              <source src={LGVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button className="back-to-gallery-btn" onClick={handleBackToGallery}>
              Back to Gallery
            </button>
          </div>
        )}
      </div>
      {/* Conditionally render the dashboard if not on LG Smart TV */}
      {!isLGTV && (
        <div className="upload-container">
          <Dashboard
            uppy={uppy}
            disableInformer={false}
            proudlyDisplayPoweredByUppy={true}
            height={400}
            plugins={['Dropbox']}
            disableThumbnailGenerator={false}
          />
        </div>
      )}
    </>
  );

}
export default withAuthenticator(Upload);
