/* global webOS */

import React, {useState} from 'react';
import '../../assets/stylesheets/back-to-lg-modal.css';

const BackToLGModal = ({ isVisible, onClose }) => {
    const [error, setError] = useState(null);
    if (!isVisible) return null;

    const handleConfirm = () => {
        try {
          if (typeof webOS !== 'undefined') {
            webOS.platformBack(); // Platform-specific method for LG Smart TV
          } else {
            window.close(); // Fallback for non-LG platforms
          }
        } catch (error) {
          console.error("Error closing the app:", error);
          setError("Error occurred. Please try again later or use your remote's back button.");
        }
      };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h5 className="modal-title">Are you sure?</h5>
                </div>
                <div className="modal-body">
                    {error ? (
                        <div className="error-message">{error}</div>
                    ) : (
                        <>
                            <div className='modal-buttons'>
                                <button className="button confirm" onClick={handleConfirm}>Exit</button>
                                <button className="button cancel" onClick={onClose}>Stay</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BackToLGModal;
