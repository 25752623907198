export const steps = [
    {
      id: 'Upload',
      title: 'Upload',
      text: [
        `
        <p>
        Upload files and digital assets.
        </p>

        `
      ],
      attachTo: { element: '#upload-button', on: 'bottom' },
      classes: 'shepherd shepherd-welcome',
      buttons: [
        {
          type: 'cancel',
          classes: 'shepherd-button-secondary',
          text: 'Exit'
        },
        {
          type: 'next',
          text: 'Next'
        }
      ]
    },
    {
      id: 'create-category',
      title: 'Create Categories',
      text:
        'Create categories',
      attachTo: { element: '#create-kin-cat-button', on: 'bottom' },
      buttons: [
        {
          type: 'back',
          classes: 'shepherd-button-secondary',
          text: 'Back'
        },
        {
          type: 'next',
          text: 'Next'
        }
      ]
    },
    {
      id: 'view-gallery',
      title: 'View Gallery',
      text: [
        'View the organized gallery.'
      ],
      attachTo: { element: '#view-files-button', on: 'bottom' },
      buttons: [
        {
          type: 'back',
          classes: 'shepherd-button-secondary',
          text: 'Back'
        },
        {
          type: 'next',
          text: 'Next'
        }
      ]
  }
];
