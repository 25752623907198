/* eslint-disable */

import { useCallback, useState, useEffect, useRef } from 'react';
import { throttle } from 'lodash';
import { postRequestSignedUrls, getRequestAssets } from '../../assets/requests'
import { handleModalOpen } from 'src/components/Gallery/modals';
import "../../assets/stylesheets/assets.css";
import placeholderImg from "../../assets/images/camera.jpg"
import moment from 'moment';
import { useNavigate } from 'react-router-dom';




    const InfiniteScroll = (props) => {
    const userId = props.userId;
    const dispatch = props.dispatch;
    const state = props.state;
    const navigate = useNavigate();

    let isLoading = props.isLoading;
    let setIsLoading = props.setIsLoading;
    const [ error, setError] = useState(null);
    const [loadedImages, setLoadedImages] = useState({});


    const currentPage = useRef(1)
    const config = { userId: userId,
                        pageLimit: 20,
                        currentPage: currentPage,
                        categories: state.selectedCategories ,
                        state: state,
                        dispatch: dispatch
                    }


// Functions --------------------------------------------------------------
    const fetchData = useCallback(async () => {
        if (isLoading) { return }
        setIsLoading(true);
        setError(null);

        try {
            await getAssets(config)
        } catch (err) {
            setError(error);
            console.log('Error occured:', error)
        } finally {
            setIsLoading(false);
        }
    }, [config, dispatch, error]);

    function handleLoadMore(e) {
        //console.log('classList:', e.target.classList)
        e.target.classList.remove('active')
        e.target.classList.add('disabled')
        e.target.innerHTML = `<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`; // Changed to wheel loader
        fetchData()
        //console.log("load more is clicked")
    }

    function delta(source, destination) {
        let sourceIds = Object.keys(source)
        let destinationIds = Object.keys(destination)
        let differenceIds = sourceIds.filter(x => !destinationIds.includes(x));
        let difference = {}
        differenceIds.forEach(differenceId => difference[differenceId] = source[differenceId] )
        return difference
    }

    async function sign(userId, collection) {
        let collectionIds = Object.keys(collection);
        let thumbnailKeys = [];
        let s3Uris = [];

        collectionIds.forEach(id => {
            // Process thumbnail URL for signing
            if (collection[id].thumbnails && collection[id].thumbnails.length > 0) {
                let parsedKey = collection[id].thumbnails[1].split('/');
                let slicedKey = parsedKey.slice(4);
                slicedKey.unshift('thumb');
                let joinedKey = slicedKey.join('/');
                thumbnailKeys.push(joinedKey);
            }

            // A little text manip magic to match format to thumb format.
            let s3Uri = collection[id].s3uri
            let joinedKey;

            // Handle Processed uri's differently than full
            if (s3Uri.includes('processed')) {
                s3Uri = s3Uri.split('/')
                // remove s3:/
                s3Uri.shift()
                s3Uri.shift()
                s3Uri.unshift('processed')
            } else {
                s3Uri = s3Uri.split('/').slice(-1)
                s3Uri.unshift('full')
            }
                joinedKey = s3Uri.join('/')
                s3Uris.push(joinedKey);
        });

        // Sign thumbnail URLs
        let signedThumbnailUrls = await postRequestSignedUrls({ userId, payload: thumbnailKeys });
        // Sign actual S3 URIs
        let signedS3Uris = await postRequestSignedUrls({ userId, payload: s3Uris });
        // Merge the signed URLs into the collection

        collectionIds.forEach((id, idx) => {
            if (signedThumbnailUrls && signedThumbnailUrls[idx]) {
                collection[id].src = signedThumbnailUrls[idx];
            }
            if (signedS3Uris && signedS3Uris[idx]) {
                collection[id].s3uri = signedS3Uris[idx];
            }
        });

        return collection;
    }

    const trigger = (el, etype, custom) => {
        if (!el) {
            console.error("Element does not exist:", el);
            return;
        }
        const evt = custom ?? new Event(etype, { bubbles: true });
        el.dispatchEvent(evt);
    };

    const largeScreenStyles = {
        width: '500px',
        height: '500px'
    };

    const isLargeScreen = window.innerWidth >= 3840 && window.innerHeight >= 2160;
    const isSmallScreen = window.innerWidth < 450;

// Use Effects --------------------------------------------------------------------------

useEffect(() => {
    if (Object.keys(state.publishedAssets).length === 0 && state.showAssets) {
      navigate('/upload');
    }
  }, [state.publishedAssets, state.showAssets, navigate]);

    useEffect(() => {
         sign(userId, delta(state.rawAssets, state.signedAssets))
         .then(deltas => {
             dispatch({ type: 'append_signed_assets', payload: deltas })
         })
    }, [state.rawAssets])

   useEffect(() => {
        let publishedAssets = delta(state.signedAssets, state.publishedAssets)
        dispatch({ type: 'append_published_assets', payload: publishedAssets })
    }, [state.signedAssets])

    useEffect(() => {
            dispatch({ type: "set_show_assets", payload: false })
            dispatch({ type: "set_raw_assets", payload: {}})
            dispatch({ type: "set_signed_assets", payload: {}})
            dispatch({ type: "set_published_assets", payload: {}})

            currentPage.current = 1;
            fetchData()
    }, [state.selectedCategories])

    useEffect(() => {
        if (isLoading) {
            let target = document.getElementsByClassName('load-more-btn')[0];
            console.log('Target:', target)
            //target.classList.remove('disabled')
            //target.classList.add('active')
            //target.textContent = "Load More"
        }
    }, [isLoading])

    useEffect(() => {
        const handleScroll = () => {
            const {
                scrollTop,
                scrollHeight,
                clientHeight
            } = document.documentElement;

            if (scrollTop + clientHeight < scrollHeight - 5|| isLoading || state.modalActive ) {
                //if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isLoading) {
                    return;
                } else {
                    //console.log('scrollTop + clientHeight:', scrollTop + clientHeight)
                    //console.log('scrollHeight:', scrollHeight - 5)
                    //fetchData();
                    let target = document.getElementsByClassName('load-more-btn')[0];
                    trigger( target, 'click')
                }
        };

        window.addEventListener('scroll', throttle(handleScroll, 500));
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading, fetchData]);

    /* LG TV REMOTE  */
    const sectionRef = useRef(null);
    const loadMoreButtonRef = useRef(null);

    useEffect(() => {
        if (sectionRef.current) {
            sectionRef.current.focus();
        }
      }, []);

    const scrollUp = useCallback(() => {
      console.log("Scrolling up ", sectionRef.current);
      if (sectionRef.current) {
          sectionRef.current.scrollBy({ top: -200, behavior: 'smooth' });
      }
    }, [sectionRef]);

    const scrollDown = useCallback(() => {
      console.log("Scrolling Down", sectionRef.current)
      if (sectionRef.current) {
          sectionRef.current.scrollBy({ top: 200, behavior: 'smooth' });
      }
    }, [sectionRef]);

    const clickLoadMoreButton = () => {
        if (loadMoreButtonRef.current) {
          loadMoreButtonRef.current.click();
        }
        console.log('clickLoadMoreButton')
      };

      useEffect(() => {
        const handleKeyPress = (e) => {
          switch (e.code) {
            case 'ArrowUp': // Up arrow
            scrollUp();
            break;
            case 'ArrowDown': // Down arrow
            scrollDown();
            clickLoadMoreButton(); // Trigger the "Load More" button click
            break;
            default:
              break;
          }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
          window.removeEventListener('keydown', handleKeyPress);
        };
      }, [scrollUp, scrollDown, clickLoadMoreButton]);


      const handleWheel = useCallback((event) => {
        if (event.deltaY < 0) {
          scrollUp();
        } else if (event.deltaY > 0) {
          scrollDown();
          clickLoadMoreButton(); // Trigger the "Load More" button click
          handleLoadMore(event);
        }
      }, [scrollUp, scrollDown]);


      useEffect(() => {
        const scrollContainer = sectionRef.current;
        if (scrollContainer) {
          scrollContainer.addEventListener('wheel', handleWheel);
        }

        return () => {
          if (scrollContainer) {
            scrollContainer.removeEventListener('wheel', handleWheel);
          }
        };
      }, [handleWheel]);

// JSX-----------------------------------------------------------------

const loaderHtml = () => {
    return (

      <div className="center">
        <button
          ref={loadMoreButtonRef}
          className="ui active button load-more-btn"
          onClick={(e) => handleLoadMore(e)}
        >
          Load More
        </button>
      </div>
    );
  };

  const handleDownArrowClick = (e) => {
    scrollDown();
    handleLoadMore(e);
};


      const handleBackToTopArrow = useCallback(() => {
        console.log("Attempting to scroll to top", sectionRef.current);
        if (sectionRef.current) {
            sectionRef.current.scrollBy({ top: -5000, behavior: 'smooth' });
        }
      }, [sectionRef]);

const assetsHtml = (props) => {
    // Determine the title based on the selected category
    let title;
    if (props.state.selectedCategories.length === 2) {
        title = `${props.state.selectedCategories[0].name} - ${props.state.selectedCategories[1].name}`;
    } else if (props.state.selectedCategories.length === 1 && props.state.selectedCategories[0].type === 'year') {
        title = `Year - ${props.state.selectedCategories[0].name}`;
    } else {
        title = "Gallery";
    }

    //Sort assets by the most current date
    const sortedAssets = Object.keys(props?.state.publishedAssets)
    .sort((a, b) => {
      const dateA = moment(props.state.publishedAssets[a].metadata?.content?.date || props.state.publishedAssets[a].createdAt);
      const dateB = moment(props.state.publishedAssets[b].metadata?.content?.date || props.state.publishedAssets[b].createdAt);

      return dateA.diff(dateB);
    });


    return (
        <section className="main-level03"  ref={sectionRef}  tabIndex="0">
            <div className="below-level03" >
                <h2 className='row-title2'>{title}</h2>
                <div className="cat-container-level02">
                    <div className="gallery-thumb-container-l3">
                        {sortedAssets.map((assetId, idx) => {
                           const asset = props?.state.publishedAssets[assetId];
                           const assetSrc = asset.src;

                           // Extract metadata date from each asset
                           const metadataDate = asset.metadata && asset.metadata.content && asset.metadata.content.date
                           ? moment(asset.metadata.content.date).format('DD MMMM YYYY')
                           : 'Date not available';
                            return (
                                <div className="center-contents-block" key={'asset-' + idx}>
                                    <div
                                        className="gallery-thumb"
                                        id={`gallery-thumb-${idx}`}
                                        style={{
                                            backgroundImage: `url('${loadedImages[assetId] ? assetSrc : placeholderImg}')`,
                                            backgroundSize: loadedImages[assetId] ? 'cover' : '100%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundColor: loadedImages[assetId] ? 'transparent' : '#D9D9D9',
                                            ...isLargeScreen
                                                ? largeScreenStyles
                                                : isSmallScreen
                                                ? { width: '120px', height: '120px' }
                                                : { width: '200px', height: '200px' }
                                        }}
                                        onClick={() =>
                                            handleModalOpen({
                                                state: props?.state,
                                                dispatch: props?.dispatch,
                                                payload: {
                                                    src: assetSrc,
                                                    assetIdx: assetId
                                                }
                                            })
                                        }
                                    >
                                        <div className="date-overlay">{metadataDate}</div> {/* Date overlay */}
                                        <img
                                            src={assetSrc}
                                            alt=""
                                            style={{ display: 'none' }}
                                            onLoad={() => {
                                                setLoadedImages((prevState) => ({
                                                    ...prevState,
                                                    [assetId]: true
                                                }));
                                            }}
                                        />

                                    </div>
                                </div>
                            );
                        })}
                    </div>
                  <div className="load-more-container">
                    {state.showAssets && loaderHtml()}
                </div>
                </div>
            </div>
            <div>
            <div  onClick={ scrollUp }className="up_arrow_invisible_assets"></div>
            <div onClick={ handleDownArrowClick}className="down_arrow_invisible_assets"></div>
            <div onClick={ handleBackToTopArrow} className="top_arrow_invisible_assets"></div>

        </div>
        </section>
    );
};

// Return ------------------------------

return < div className='assets'>
    <div className="assets-container">
        {state.showAssets && assetsHtml({ state: state, dispatch: dispatch })}
    </div>
    {/* <div className="load-more-container">
        {state.showAssets && loaderHtml()}
    </div> */}
</div>

};

export default InfiniteScroll;


async function getAssets(config) {
    const assetType = {
        id: '',
        name: '',
        metadata: {},
        categoryIds: [],
        thumbnails: [],
        src: '',
        key: '',
        createdAt: '',
        src2: '',
        s3uri: '',

    }

    async function mapToKinstakAssets(fromMongo) {
    if (!Array.isArray(fromMongo)) {
        console.error('Expected an array, but received:', fromMongo);
        return {};
    }

    var assets = {};
    await fromMongo.forEach(key => {
        const kinstakAsset = {
            id: '',
            name: '',
            metadata: {},
            categoryIds: [],
            thumbnails: [],
            src: '',
            key: '',
            createdAt: '',
            s3uri: '',
            src2: '',
        }

        kinstakAsset.id = key.id
        kinstakAsset.name = ''
        kinstakAsset.s3uri = key.src
        kinstakAsset.src = ''
        kinstakAsset.s3uri = key.s3uri;
        kinstakAsset.src2 = key.src2;
        kinstakAsset.createdAt = key.createdAt
        kinstakAsset.thumbnails = key.thumbnails
        kinstakAsset.metadata['categories'] = key.categories
        kinstakAsset.metadata['content'] = key.content
        kinstakAsset.key = key['s3uri'].split('/').slice(-1)[0]
        assets[key.id] = kinstakAsset
    });
    //console.log("Mapped assets:", assets); // Log the assets after mapping

    return assets
}





    async function QueryAssets(config) {
        const userId = config.userId;

        let mongoAssets = await getRequestAssets(config)

        if (mongoAssets?.length === 0) {
            const loadMoreButton = document.getElementsByClassName('load-more-btn')[0];
            if (loadMoreButton) loadMoreButton.style.display = "none"; // Hide the Load More button if no items are left
            return []
        }

        const mappedAssets = await mapToKinstakAssets(mongoAssets)
        config.dispatch({ type: 'append_raw_assets', payload: mappedAssets })
    }
    //let kinstakAssets = await QueryAssets(config);
    QueryAssets(config)
    //return await kinstakAssets
}
