import React from 'react';
import homeIcon from '../assets/images/home-icon.png';
import '../assets/stylesheets/gallery-nav.css';

const NavigationComponent = ({ handleHomeClick}) => {
    return (
        <div className="navigation-component">
            <img className='nav-icon-home home categories' alt="Go to base of navigation" src={homeIcon} onClick={() => { console.log('Home icon clicked'); handleHomeClick(); }} />
        </div>
    );
};

export default NavigationComponent;
