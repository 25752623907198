import * as Realm from 'realm-web'

function GetQueryObject() {
    const realmAppId = 'kinstak-test-stjvk'
    const realmApp = new Realm.App({id: realmAppId})

    try {
        async function GetData () {
            await realmApp.logIn(Realm.Credentials.anonymous())
            // ENABLE when removing Carolyns hard coded guid
            //let data = await Auth.currentAuthenticatedUser()
            const client = realmApp.currentUser.mongoClient('mongodb-atlas')
            try {
                let someone = '13e1f37a-ab56-4557-b390-ad8a0504db19'
                let queryObject = client.db(someone).collection('fotos')
                return queryObject
            } catch(err) {
                    console.log('setQueryObject: ', err);
            }
        }
        return GetData();
    } catch(err) {
        console.log(`User Error: ${ err }`);
    }
}

export default GetQueryObject
