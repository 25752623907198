import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

const VacationCategory = () => {
    const [vacationTitle, setVacationTitle] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [location, setLocation] = useState('');
    const [vacations, setVacations] = useState([]);
    const [userId, setUserId] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setUserId(user.username);
            })
            .catch(err => console.error('Error fetching authenticated user', err));
    }, []);

    const validateCategoryData = (data) => {
        // Check for non-empty vacation title
        if (!data.name || data.name.trim() === '') {
            setMessage('Vacation title cannot be empty');
            return false;
        }
    
        // Check character limits
        if (data.name.length > 100) { 
            setMessage('Vacation title is too long');
            return false;
        }
    
        if (data.calendarAttrs.location && data.calendarAttrs.location.length > 200) { 
            setMessage('Location is too long');
            return false;
        }
    
        // Date validation
        if (data.calendarAttrs.fromDate && data.calendarAttrs.toDate) {
            const fromDate = new Date(data.calendarAttrs.fromDate);
            const toDate = new Date(data.calendarAttrs.toDate);
    
            if (fromDate > toDate) {
                setMessage('From date cannot be later than To date');
                return false;
            }
        }
    
        return true;
    };

    const customPostRequest = async (url, data) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorBody = await response.json().catch(() => ({}));
                const errorMessage = errorBody.message || `Server responded with status: ${response.status}`;
                throw new Error(errorMessage);
            }

            return await response.json().catch(e => {
                throw new Error(`Error parsing JSON: ${e.message}`);
            });
        } catch (error) {
            if (error.name === 'TypeError' && error.message.includes('NetworkError')) {
                throw new Error('Network error: Please check your internet connection.');
            }
            console.error('Error in customPostRequest:', error);
            throw error;
        }
    };

    const handleCreate = async () => {
        if (!userId) {
            setMessage('User not authenticated');
            return;
        }

        if (!vacationTitle || !(fromDate || toDate || location)) {
            setMessage('Please enter vacation title and at least one of date or location');
            return;
        }

        const categoryData = {
            userId: userId,
            name: vacationTitle,
            type: "leisure",
            subType: "vacation",
            calendarAttrs: {
                fromDate: fromDate ? fromDate + "T00:00:00Z" : undefined,
                toDate: toDate ? toDate + "T23:59:59Z" : undefined,
                location: location
            }
        };

        if (!validateCategoryData(categoryData)) {
            setMessage('Invalid category data');
            return;
        }

        try {
            const apiURL = `${process.env.REACT_APP_API_GATEWAY_URL}/k1/users/${userId}/categories`;
            const responseData = await customPostRequest(apiURL, categoryData);

            if (responseData.error) {
                throw new Error(responseData.error);
            }

            setVacations(prev => [...prev, { vacationTitle, fromDate, toDate, location }]);
            setVacationTitle('');
            setFromDate('');
            setToDate('');
            setLocation('');
            setMessage('Category created successfully');
        } catch (error) {
            console.error('Error in handleCreate:', error);
            setMessage(`Failed to create category: ${error.message}`);
        }
    };

    return (
        <div className="vacation-category">
            <h2 className="category-header">Vacation</h2>
            <p className="asterix-message">* Select a date, a date range, location or both!</p>
            {message && <p className="message">{message}</p>}
            <input
                type="text"
                placeholder="Vacation Title"
                value={vacationTitle}
                onChange={(e) => setVacationTitle(e.target.value)}
                className="input-field"
            />
            <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="input-field"
            />
            <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="input-field"
            />
            <input
                type="text"
                placeholder="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="input-field"
            />
            <div className="button-group">
                <button onClick={handleCreate} className="create-button">Create</button>
            </div>
            <ul>
                {vacations.map((vacation, index) => (
                    <li className="added-list" key={index}>
                        {vacation.vacationTitle} - 
                        {vacation.fromDate && `${vacation.fromDate} to `}
                        {vacation.toDate && vacation.toDate}
                        {vacation.location && ` at ${vacation.location}`}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default VacationCategory;
