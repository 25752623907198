import React, { useEffect } from 'react';
import { Amplify, Hub, Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import { Authx, Dashboard, Gallery, Upload, Kategories, Subscribe } from './pages';
import { login } from './store/user'
import Header from 'src/components/Header/Header'
import BirthdayCategory from './pages/categories/birthdayCategory'
import VacationCategory from './pages/categories/vacationCategory';
import WeddingCategory from './pages/categories/weddingCategory';
import FriendsAndFamilyCategory from './pages/categories/friendsAndFamilyCategory';
import PetsCategory from './pages/categories/petsCategory';
import EventsAndLocationsCategory from './pages/categories/eventsAndLocationsCategory';
import CustomCategory from './pages/categories/customCategory';
import DuplicatesCategory from './pages/categories/duplicatesCategory';
import FavoritesCategory from './pages/categories/favoritesCategory';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import Footer from './components/Footer/Footer'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { hotjar } from 'react-hotjar';
import { ShepherdTour } from 'react-shepherd';
import { options as shepherdOptions, steps as shepherdSteps } from './assets/shepherd';

Amplify.configure(awsExports);
hotjar.initialize({ id: process.env.REACT_APP_HOTJAR_ID, sv: process.env.REACT_APP_HOTJAR_SV});


function SignOutListener() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    Hub.listen("auth", ({payload: { event, data }}) => {
      switch (event) {
        case "signIn":
          console.log('signIn:', data.username);
          console.log('whats going on here?')
          dispatch(login({ username: data.username }))
          //amplitude.track("Sign In", { userId: data?.username });
          break;
        case "signedIn":
          console.log('signedin')
          break;
        case "signUp":
          console.log('singup: ', data);
          //amplitude.track("Sign Up", { userId: data?.username });
          break;
        case "signOut":
          console.log('SignOut App');
          //amplitude.track("Sign Out", { userId: data?.username });
          navigate("/auth");
          break;
        case "signIn_failure":
          console.log('singIn failure: ', data);
          // amplitude.track("Sign In Failure");
          break;
        case "configured":
          break;
        case "customOAuthState":
          break;
        default:
         // console.log('event:', event, ', data:', data);
          break;
      }
    });
  }, [dispatch, navigate]);

  return null;
}
function RootRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    async function checkAuthStatus() {
      try {
        await Auth.currentAuthenticatedUser();
        navigate('/gallery');
      } catch (err) {
        navigate('/auth');
      }
    }

    checkAuthStatus();
  }, [navigate]);

  return null;
}


  function App() {
    useEffect(() => {
      const handleMouseEvents = (event) => {
        if (event.detail > 1) {
          event.preventDefault();
          event.stopPropagation();
        }
      };

      window.addEventListener('mousedown', handleMouseEvents, true);
      window.addEventListener('click', handleMouseEvents, true);

      return () => {
        window.removeEventListener('mousedown', handleMouseEvents, true);
        window.removeEventListener('click', handleMouseEvents, true);
      };
    }, []);

  return (
    <div>
      <ShepherdTour steps={shepherdSteps} tourOptions={shepherdOptions}>
        <Router>
          <ToastContainer />
          <Header />
          <MainContent />
        </Router>
      </ShepherdTour>
    </div>
  );
}

function MainContent() {
  const appLocation = useLocation();

  return (
    <>
      <SignOutListener />
      <Routes>
          <Route path="/" element={<RootRedirect />} />
          <Route exact path="/auth" element={ <Authx/> } />
          <Route exact path="/dashboard" element={ <Dashboard /> } />
          <Route exact path="/gallery" element={<Gallery /> } />
          <Route exact path="/upload" element={ <Upload /> } />
          <Route exact path="/subscribe" element={ <Subscribe /> } />
          <Route exact path="/kin-categories" element={ <Kategories /> } />
          <Route exact path="/birthday-category" element={<BirthdayCategory />} />
          <Route exact path="/vacation-category" element={ <VacationCategory /> } />
          <Route exact path="/wedding-category" element={ <WeddingCategory /> } />
          <Route exact path="/friends-family-category" element={ <FriendsAndFamilyCategory /> } />
          <Route exact path="/pets-category" element={ <PetsCategory /> } />
          <Route exact path="/events-locations-category" element={ <EventsAndLocationsCategory /> } />
          <Route exact path="/custom-category" element={ <CustomCategory /> } />
          <Route exact path="/duplicates" element={ <DuplicatesCategory /> } />
          <Route exact path="/favorites" element={ <FavoritesCategory /> } />

      </Routes>
      {(appLocation.pathname === "/auth" || appLocation.pathname === "/") && <Footer />}
    </>
  );
}

export default App;
