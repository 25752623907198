import React, { useState } from 'react';
import userAvatar from '../../assets/images/user-avatar.jpg';
import rightArrow from '../../assets/images/46_Arrow_Right.jpg';
import reloadArrow from '../../assets/images/reload-arrow2.jpg';

const CombinedCategory = () => {
    const [categoryTitle, setCategoryTitle] = useState('');
    const [members, setMembers] = useState([]);
    const [avatarStartIndex, setAvatarStartIndex] = useState(0);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [location, setLocation] = useState('');

    const avatars = new Array(10).fill(userAvatar);

    const handleAddMember = () => {
        if (categoryTitle && fromDate && toDate && location) {
            setMembers(prev => [...prev, { name: categoryTitle, fromDate, toDate, location }]);
            setCategoryTitle('');
            setFromDate('');
            setToDate('');
            setLocation('');
        }
    };

    const handleShowMoreAvatars = () => {
        if (avatarStartIndex + 4 < avatars.length) {
            setAvatarStartIndex(prev => prev + 4);
        } else {
            setAvatarStartIndex(0);
        }
    };

    const isAtEnd = avatarStartIndex + 4 >= avatars.length;

    return (
        <div className="combined-category">
            <h2 className="category-header">Custom Category</h2>
            <input
                type="text"
                placeholder="Name"
                value={categoryTitle}
                onChange={(e) => setCategoryTitle(e.target.value)}
                className="input-field custom"
            />

            <div className='custom-avatars-and-arrow'>
                <div className="custom-members-icons">
                    {avatars.slice(avatarStartIndex, avatarStartIndex + 4).map((avatar, index) => (
                        <div key={index} className="member-icon">
                            <div className="icon">
                                <img src={avatar} alt={`User ${index + avatarStartIndex + 1}`} />
                            </div>
                            <span className="icon-name">Name {index + avatarStartIndex + 1}</span>
                        </div>
                    ))}
                </div>
                <div className="member-icon arrow-icon" onClick={handleShowMoreAvatars}>
                    <img src={isAtEnd ? reloadArrow : rightArrow} alt={isAtEnd ? "Reload" : "Next"} className="icon-image" />
                </div>
            </div>

            <div className="date-inputs">
                <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="input-field"
                />
                <input
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    className="input-field"
                />
            </div>

            <input
                type="text"
                placeholder="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="input-field custom-location"
            />

            <div className="button-group custom">
                <button onClick={handleAddMember} className="create-button">Create</button>
                <button onClick={() => {
                    handleAddMember();
                }} className="add-button">+Add</button>
            </div>

            <ul>
                {members.map((member, index) => (
                    <li className="added-list" key={index}>
                        {member.name} - From: {member.fromDate} To: {member.toDate} at {member.location}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CombinedCategory;
