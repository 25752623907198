import React, {useEffect} from "react";
import { MetadataContainer } from "./metadata"
//import favStar from '../../assets/images/fav-star.png';
import {postFavorites, deleteAsset} from '../../assets/requests'
import { toast } from 'react-toastify';
import leftArrow from '../../assets/images/lft-arrow.png';
import rightArrow from '../../assets/images/rt-arrow.png';
import cancelBtn from '../../assets/images/cancel.png';
import deleteImg from '../../assets/images/delete2.svg';
//import emptyHeart from '../../assets/images/empty-heart.svg';
import filledHeart from '../../assets/images/full-heart.svg';
import "../../assets/stylesheets/modal.css"

export const ModalHtml = (props) => {
    

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (props.state.modalActive) { 
                switch (e.code) {
                    case 'ArrowLeft': // Left arrow
                        handleModalArrowClick(props, 'left');
                        break;
                    case 'ArrowRight': // Right arrow
                        handleModalArrowClick(props, 'right');
                        break;
                    default:
                        break;
                }
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [props.state.modalActive, props]);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            props.onClose();
        }
    };

    return (
        <div className={`modal ${props?.state.modalActive ? 'active' : ''}`} onClick={handleOverlayClick}>
            <ModalInnerHtml {...props} onClose={props.onClose} props={props} />
        </div>
    );
    }


    const ModalInnerHtml = ({ state, dispatch, onClose, props }) => {
        const handleMakeFavorite = async () => {
            let assetId = state.currentAsset;
    
            if (!assetId) {
                assetId = state.modalData?.assetId || Object.keys(props.state.publishedAssets)[0]; 
                console.log("Fallback: Using asset ID from modalData or the first published asset");
            }
    
            console.log("Asset id to be tagged as favorite", assetId);
            const userId = localStorage.getItem("username");
            console.log("Retrieved user id from localStorage", userId);
    
            try {
                await postFavorites({
                    userId: userId,
                    payload: {
                        categories: [{
                            name: "favorite",
                            type: "tag",
                            id: "1ad60c22-2c49-4c9d-a1c4-6ba23d993cb1"
                        }],
                        assetIds: [assetId]
                    }
                });
                toast.success("Picture marked as favorite successfully!");
            } catch (error) {
                console.error("Failed to mark asset as favorite: ", error);
                toast.error("Failed to mark asset as favorite.");
            }
        };
    
        const handleDeleteAsset = async () => {
            let assetId = state.currentAsset;
    
            if (!assetId) {
                assetId = state.modalData?.assetId || Object.keys(props.state.publishedAssets)[0]; 
                console.log("Fallback: Using asset ID from modalData or the first published asset");
            }
    
            console.log("Asset id to be deleted", assetId);
            const userId = localStorage.getItem("username");
            console.log("Retrieved user id from localStorage", userId);
    
            try {
                await deleteAsset({
                    userId: userId,
                    assetId: assetId
                });
                toast.success("Picture deleted successfully!");
                onClose();
                props.onAssetDeleted();
            } catch (error) {
                console.error("Failed to delete asset: ", error);
                toast.error("Failed to delete asset.");
            }
        }

    return (
        <>
            <div className="close-modal-container">
                {state.modalStatus !== 'MetaData' && (
                    <div className={`back-arrow-container ${state.modalStatus !== "Image" ? 'active' : ''}`}>
                        <img alt="Back to asset" src={leftArrow} className="back-modal-icon" onClick={() => handleModalStatusChange({ state, dispatch }, 'Image')}></img>
                    </div>
                )}
                    <img src={cancelBtn} className="close-modal-icon" alt="" onClick={(e) => handleModalClose(props, e)} />
            </div>
            <div className="modal-title">
                {/* Title can be added here if needed */}
            </div>
            <div className="modal-center">
                <div className="modal-content01">
                    {/* {state.modalStatus === 'Tags' && <TagsContainer state={state} dispatch={dispatch} />} */}
                    {state.modalStatus === 'MetaData' && <MetadataContainer state={state} dispatch={dispatch} />}
                    {state.modalStatus === 'Image' && modalImageHtml({ state, dispatch, handleMakeFavorite, handleDeleteAsset })}
                </div>
            </div>
            <div className="modal-button-container01">
                {/* Additional buttons or content can be added here if needed */}
            </div>
        </>
    );
};



const modalImageHtml = (props) => {
    let imageUrl;
    console.log("Image URL:", props.state);
    if (props.state.modalData && props.state.modalData.asset && props.state.modalData.asset.s3uri) {
        imageUrl = props.state.modalData.asset.s3uri; 
    } else {
        const currentAssetId = props.state.currentAsset;
        const asset = props.state.assets.find(asset => asset.id === currentAssetId);
        imageUrl = asset ? asset.src : ''; 
    }

    const isFavoritesPage = window.location?.pathname === '/favorites';
    const isGalleryPage = window.location?.pathname === '/gallery';
    const isDuplicatesPage = window.location?.pathname === '/duplicates';

    return (
        <div className="modal-title-image">
            <div className='modal-body'>
                {props?.state.modalStatus !== 'MetaData' && (
                    <div className="scroll-container lf-arrow">
                        <img src={leftArrow} alt="" className="scroll-arrow" onClick={() => handleModalArrowClick(props, 'left')} />
                    </div>
                )}
                <div className="modal-main-window">
                    <img className="modal-main-image fluid-image" alt="" src={imageUrl} />
                    <div className="modal-icon-row">
                        <div className="modal-icon-container">
                            <div className="left-side-modal-buttons">
                                <div className="modal-button-container">
                                    {/* Show "MAKE IT A FAVORITE" button only on /gallery page */
                                     isGalleryPage && (
                                        <button onClick={() => props.handleMakeFavorite()} className="button-med favorite-button">
                                            <img src={filledHeart}  alt="Emptyheart"  className='fav-img-btn'/>
                                        </button>
                                    )}
                                    {/* Show "DELETE" button on /favorites and /duplicates pages */
                                     (isFavoritesPage || isDuplicatesPage) && (
                                        <button className="button-med delete-button" onClick={() => props.handleDeleteAsset()}>
                                          <img src={deleteImg}  alt="Delete"  className='delete-img-btn'/>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {props?.state.modalStatus !== 'MetaData' && (
                    <div className="scroll-container rt-arrow">
                        <img src={rightArrow} alt="" className="scroll-arrow" onClick={() => handleModalArrowClick(props, 'right')} />
                    </div>
                )}
            </div>
        </div>
    );
};


export function handleModalOpen(props) {
    //console.log('handleModalOpen called with props:', props);
    let assetToBeDisplayed;
    if ('assetId' in props.payload) {
        const assetId = props.payload.assetId;
        //console.log('Attempting to find asset with ID:', assetId);
        assetToBeDisplayed = props.state.publishedAssets.find(asset => asset.id === assetId);
    }
    else if ('assetIdx' in props.payload) {
        const assetIdx = props.payload.assetIdx;
        //console.log('Attempting to find asset with Index:', assetIdx);
        assetToBeDisplayed = props.state.publishedAssets[assetIdx];
    }

    if (assetToBeDisplayed) {
        props.dispatch({
            type: 'set_modal_data',
            payload: {
                asset: assetToBeDisplayed,
                assetId: assetToBeDisplayed.id
            }
        });
        props.dispatch({ type: 'set_modal_active', payload: true });
    } else {
        console.error('Asset not found.');
    }
}



 
const handleModalStatusChange = (props, status) => {
    props.dispatch({ type: 'set_modal_status', payload: status })
    //setModalStatus(status);
}


function handleModalClose(props, e) {
    console.log('props to close modal', props);
    if (e.target.classList.contains('modal') || e.target.classList.contains('close-modal-icon')) {

        props?.dispatch({ type: 'set_modal_active', payload: false });
        props?.dispatch({ type: 'set_modal_status', payload: 'Image' });

        if (typeof props.onClose === 'function') {
            props.onClose();
        } else {
            console.error("onClose is not available or not a function", props);
        }
    }
}


function getSortedAssets(assets) {
    return Object.values(assets).sort((a, b) => {
        const dateA = new Date(a.metadata.content.date);
        const dateB = new Date(b.metadata.content.date);
        return dateA - dateB;
    });
}

function handleModalArrowClick(props, direction) {
    const sortedAssets = getSortedAssets(props.state.publishedAssets);
    const currentAssetId = props.state.modalData.assetId;
    const currentIdx = sortedAssets.findIndex(asset => asset.id === currentAssetId);

    let newIndex = currentIdx;
    if (direction === 'left') {
        newIndex = currentIdx > 0 ? currentIdx - 1 : sortedAssets.length - 1; 
    } else if (direction === 'right') {
        newIndex = currentIdx < sortedAssets.length - 1 ? currentIdx + 1 : 0; 
    }

    const newAsset = sortedAssets[newIndex];
    if (newAsset) {
        console.log('Switching to asset:', newAsset);
        props.dispatch({
            type: 'set_modal_data',
            payload: { asset: newAsset, assetId: newAsset.id }
        });
    }
}

// function handleModalArrowClick(props, direction) {
//     console.log('Modal Arrow Click:', props);

//     const allAssetIds = props.state.assets.map(asset => asset.id);
//    // console.log('All Asset Ids:', allAssetIds);
//     const currentIdx = allAssetIds.indexOf(props.state.currentAsset);
//     console.log('Current Index:', currentIdx);

//     let newIndex;
//     if (direction === 'left') {
//         newIndex = currentIdx - 1 < 0 ? allAssetIds.length - 1 : currentIdx - 1;
//     } else if (direction === 'right') {
//         newIndex = currentIdx + 1 === allAssetIds.length ? 0 : currentIdx + 1;
//     }

//     const newAssetId = allAssetIds[newIndex];

//     if (newAssetId) {
//         const newAsset = props.state.assets.find(asset => asset.id === newAssetId);
//         console.log('Switching to asset:', newAsset);
//         props.dispatch({
//             type: 'set_modal_data',
//             payload: { currentAsset: newAssetId }
//         });
//     }
// }



