import "../../assets/stylesheets/metadata.css"

export function MetadataContainer(props) {
    let metadata = props?.state.modalData.asset.metadata;
    let keys = Object.keys(metadata)
    
    return <div className="metadata-container">
        {   keys.length === 0 &&
                <div className="inner-metadata">No Metadata found</div>
        }
       { keys.map((key, idx) => {
            if (key === 'coordinates') {
                return <div className="inner-metadata" key={ 'metadata-' + idx }>
                    Coordinates:<a target="_blank" rel="noreferrer" href={ "https://www.google.com/maps/place//@" + metadata[key] }>{ metadata[key]}</a>
                    </div>
            } else {
            return <div className="inner-metadata"  key={ 'metadata-' + idx } >{ key + ' : ' + JSON.stringify(metadata[key]) }</div>}
        })
        }
    </div>
}