import { createSlice } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify';

// Slice
const slice = createSlice({
    name: 'user',
    initialState: {
      user: null
    },
    reducers: {
      loginSuccess: (state, action) => {
        state.user = action.payload;
      },
      logoutSuccess: (state, action) =>  {
        state.user = null;
      }
    },
  });

export default slice.reducer

// Actions
const { loginSuccess, logoutSuccess } = slice.actions

export const login = ({ username, idToken }) => async dispatch => {
  try {
    // const res = await api.post('/api/auth/login/', { username, password })
    localStorage.setItem('username', username)
    dispatch(loginSuccess({ username: username, idToken: idToken }));
  } catch (e) {
    return console.error(e.message);
  }
}
export const logout = () => async dispatch => {
  try {
    // const res = await api.post('/api/auth/logout/')
    localStorage.setItem('username', null)
    await Auth.signOut();
    return dispatch(logoutSuccess())
  } catch (e) {
    return console.error(e.message);
  }
}
