import React, { useCallback, useEffect, useReducer, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { GetNavigation } from '../components/Gallery'
import { withAuthenticator } from '@aws-amplify/ui-react';
//import "../assets/stylesheets/gallery-style.css"
import "../assets/stylesheets/gallery-nav.css"
//import "../assets/stylesheets/gallery-tv-style.css"
import {useNavigate} from 'react-router-dom';

import upArrow from '../assets/images/up-arrow.png';
import leftArrow from '../assets/images/lft-arrow.png';
import rightArrow from '../assets/images/rt-arrow.png';
import backArrow from '../assets/images/back-arrow3.png';
import homeIcon from '../assets/images/home-icon.png'
import placeholderImg from '../assets/images/camera-icon-bg2.jpg'

import { handleRowScroll, calculateProgressBar } from 'src/components/Gallery/sliders';
import { ModalHtml } from 'src/components/Gallery/modals';
import { reducer } from 'src/components/Gallery/reducer';
import InfiniteScroll from 'src/components/Gallery/assets';
import BackToLGModal from 'src/components/Header/backToLgModal';
import { isNotLGSmartTV } from '../assets/lg';
//import * as amplitude from '@amplitude/analytics-browser';

const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL

function Gallery() {
  const userId = useSelector((state) => state.user.user.username)
  const [isLoading, setIsLoading] = useState(false);
  const [loadedImagesGallery, setLoadedImagesGallery] = useState({});
  const navigate = useNavigate();
  const [showBackModal, setShowBackModal] = useState(false);
  const leftArrowRefs = useRef([]);
  const rightArrowRefs = useRef([]);
  const scrollContainerRef = useRef(null);
  const [subscription, setSubscription] = useState(1);

  const initialState = {
    navigation: [],
    assets: [],
    showAssets: false,
    rawAssets: {},
    signedAssets: {},
    publishedAssets: {},
    modalActive: false,
    modalData: {},
    modalStatus: 'Image',
    selectedCategories: [],
    loading: {
      limitSize: 10,
      canLoadMore: true,
      currentPage: 1,
      isLoading: false
    }
  }
  const [ state, dispatch ] = useReducer(reducer, initialState);

  //CHOOSE CSS BASED ON THE KIND OF DEVICE
  useEffect(() => {
    if (!isNotLGSmartTV()) {
      // If NOT running on an LG Smart TV
      require("../assets/stylesheets/gallery-style.css");
    } else {
      // If running on an LG Smart TV
      require("../assets/stylesheets/gallery-tv-style.css");
    }
  }, []);

  useEffect(() => {
    const suppressDoubleClick = (event) => {
      if (event.detail > 1) {
        event.preventDefault();
      }
    };

    window.addEventListener('mousedown', suppressDoubleClick);
    return () => {
      window.removeEventListener('mousedown', suppressDoubleClick);
    };
  }, []);

  async function fetchUserSubscription (userId)  {
    const url = `${API_GATEWAY_URL}/v1/users/${userId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        },
      });
      const data = await response.json();
      //console.log("this is the data ",data);
      return data.categoryLimit;
    } catch (error) {
      console.error('Error fetching user subscription:', error);
      return null;
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserSubscription(userId).then(subscriptionData => {
        console.log('Subscription Data:', subscriptionData);
        setSubscription(subscriptionData);
      });
    }
  }, [userId]);

  useEffect(()=> {
    document.querySelectorAll('.progress-bar').forEach(calculateProgressBar)
  },[state.navigation])



  useEffect(() => {
    GetNavigation({ userId, selectedCategories: state.selectedCategories, dispatch })
    .then(data => {
      dispatch({type: 'set_navigation', payload: data });
      console.log('Navigation Data:', data);
    });
  }, [userId, state.selectedCategories, navigate]);


  const resetSliders = () => {
    const sliders = document.querySelectorAll('.thumb-slider');
    const leftArrows = document.querySelectorAll('.lft-arrow');

    sliders.forEach(slider => {
      slider.style.setProperty("--slider-index", "0");
      slider.style.setProperty("--total-translatex", "0px");
    });

    leftArrows.forEach(arrow => {
      arrow.classList.remove('active');
      arrow.style.opacity = '0.5';
    });
  };

  useEffect(() => {
    if (state.navigation.length > 0) {
      resetSliders();
    }
  }, [state.navigation]);


  function handleBackNavigationClick(){
    dispatch({type: 'pop_selected_category' })
  }


  function handleHomeClick(){
    dispatch({type: 'reset_selected_category' })
  }

  //LG TV REMOTE CONTROL
  const handleBack = useCallback(() => {
    if (state.selectedCategories && state.selectedCategories.length > 0) {
      handleBackNavigationClick();
    } else {
      setShowBackModal(true);
    }
  }, [state.selectedCategories]);


  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.focus();
    }
  }, []);

  const handleBackToTop = () => {
    console.log(scrollContainerRef.current);
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: 10,
        behavior: "smooth"
      });
    } else {
      window.scrollTo({
        top: 100,
        behavior: 'smooth',
      });
    }
  };


  const scrollUp = useCallback((deltaY = 200) => {
    const scrollBehavior = Math.abs(deltaY) > 100 ? 'smooth' : 'auto';
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ top: -deltaY, behavior: scrollBehavior });
    }
  }, [scrollContainerRef]);

  const scrollDown = useCallback((deltaY = 200) => {
    const scrollBehavior = Math.abs(deltaY) > 100 ? 'smooth' : 'auto';
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ top: deltaY, behavior: scrollBehavior });
    }
  }, [scrollContainerRef]);


  useEffect(() => {
    const handleKeyPress = (e) => {
      switch (e.code) {
        case 'ArrowLeft': // Left arrow
        if (leftArrowRefs.current[0]) {
          leftArrowRefs.current[0].click();
        }
        break;
        case 'ArrowUp': // Up arrow
        scrollUp();
        break;
        case 'ArrowRight': // Right arrow
        if (rightArrowRefs.current[0]) {
          rightArrowRefs.current[0].click();
        }
        break;
        case 'ArrowDown': // Down arrow
        scrollDown();
        break;
        case 'BrowserBack': // Back button
        handleBack();
        break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleBack, scrollUp, scrollDown]);


    leftArrowRefs.current = [];
    rightArrowRefs.current = [];

    const addToLeftRefs = (el) => {
      if (el && !leftArrowRefs.current.includes(el)) {
        leftArrowRefs.current.push(el);
      }
    };

    const addToRightRefs = (el) => {
      if (el && !rightArrowRefs.current.includes(el)) {
        rightArrowRefs.current.push(el);
      }
    };

    const handleWheel = useCallback((event) => {
      let normalizedDeltaY = event.deltaY;
      if (Math.abs(event.deltaY) < 100) {
        normalizedDeltaY *= 2.5;
      }
      if (normalizedDeltaY < 0) {
        scrollUp(-normalizedDeltaY);
      } else if (normalizedDeltaY > 0) {
        scrollDown(normalizedDeltaY);
      }
    }, [scrollUp, scrollDown]);


    useEffect(() => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        scrollContainer.addEventListener('wheel', handleWheel);
      }

      return () => {
        if (scrollContainer) {
          scrollContainer.removeEventListener('wheel', handleWheel);
        }
      };
    }, [handleWheel]);

    function handleClick(elm) {
      //amplitude.track('Category Selected', { category: elm.name });

      if (elm.subCategories ) {
        navigate('/upload');
      } else {
        dispatch({ type: 'append_selected_category', payload: elm });
      }
    }

    const innerNavigationHtml = <>
        {state.navigation?.map((superElm, superElmIdx) => {
            // Checking if the current category is "Kin-Categories"
            if (superElm.name === "Kin-Categories") {
                // Limiting the subCategories based on the subscription state
                superElm.subCategories = superElm.subCategories.slice(0, subscription);
            }

            return <div className="categories-row-01" key={'superCategory_' + superElmIdx}>
                <div className="slider-header">
                    <span className="row-title">{superElm.name}</span>
                    <div className="progress-bar"></div>
                </div>
                <div className="main-level01-row">
                    <div className="scroll-container gallery lft-arrow" ref={addToLeftRefs}>
                        <img src={leftArrow} alt="" className="scroll-arrow" id={`lft-scroll-${parseInt(superElmIdx) + 1}`} onClick={(e) => handleRowScroll(e, 'left')} />
                    </div>
                    <div className="thumb-container" id={`scrollRow-${parseInt(superElmIdx) + 1}`}>
                        <div className="thumb-slider">
                            {superElm.subCategories?.map((elm, subCategoryIdx) => {
                                // Rendering each subCategory within the limit
                                return <div className="center-contents-block" key={'subCategoryIdx_' + subCategoryIdx}>
                                    <div className="thumb"
                                        id={`thumb-${subCategoryIdx}`}
                                        onClick={() => handleClick(elm)}
                                        style={{
                                            backgroundImage: loadedImagesGallery[elm.id] ? `url(${elm?.src})` : `url(${placeholderImg})`,
                                            backgroundSize: loadedImagesGallery[elm.id] ? 'cover' : '100%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundColor: '#D9D9D9',
                                        }}>
                                        <img src={elm?.src}
                                            alt=""
                                            onLoad={() => {
                                                setLoadedImagesGallery((prevState) => ({
                                                    ...prevState,
                                                    [elm.id]: true
                                                }));
                                            }}
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                    <span>
                                        <button className="thumb-title">{elm.name}</button>
                                    </span>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="scroll-container gallery rt-arrow" ref={addToRightRefs}>
                        <img src={rightArrow} alt="" className="scroll-arrow" id={`rt-scroll-${parseInt(superElmIdx) + 1}`} onClick={(e) => handleRowScroll(e, 'right')} />
                    </div>
                </div>
            </div>
        })}
    </>


    const navigationHtml = <>
      <section className="main-level01"  ref={scrollContainerRef}>
        <div className="header-margin"></div>
        <div className="go-back">
          <img className='nav-icon-home home' alt="Go to base of navigation" src={ homeIcon } onClick={ handleHomeClick } />
          <img className='nav-icon-back back' alt="Go back one level." src={ backArrow } onClick={ handleBackNavigationClick } />
          <img className='nav-icon-top top' alt="Go back to top." src={ upArrow } onClick={ handleBackToTop } />
        </div>
        <div  className="cat-container-level01">
            { (state.navigation?.length > 0 ) && innerNavigationHtml }
        </div>
        <div>
            <div  onClick={ scrollUp }className="up_arrow_invisible"></div>
            <div onClick={ scrollDown} className="down_arrow_invisible"></div>
        </div>
    </section>
    </>

    return < div className= 'all-assets'>
            {showBackModal && (
                <BackToLGModal
                isVisible={showBackModal}
                onClose={() => setShowBackModal(false)}
                />
            )}
      {state.modalActive && <ModalHtml state={state} dispatch={dispatch} />}
      { navigationHtml}
      <div className="infinite-scroll-container">
        <InfiniteScroll userId={userId} state={state} dispatch={dispatch} isLoading={isLoading} setIsLoading={setIsLoading} className ='infinite-scroll'/>
      </div>
    </div>
  }

export default withAuthenticator(Gallery);
