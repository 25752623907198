export function reducer(state, action) {
    switch (action.type) {
      case 'set_show_assets': {
        //console.log('setting assets:', action.payload)
        return {
          ...state,
          showAssets: action.payload
        }
      }
      case 'pop_selected_category': {
        let result = state.selectedCategories.slice(0, -1)

        return {
          ...state,
          selectedCategories: result
        }
      }
      case 'append_selected_category': {
        let result = state.selectedCategories.concat([action.payload])
        
        return {
          ...state,
          selectedCategories: result
        }
      }
      case 'reset_selected_category': {
        return {
          ...state,
          selectedCategories: [],
          showAssets: false
        }
      }
      case 'set_navigation': {
        return {
          ...state,
          navigation: action.payload
        }
      }
      case 'set_assets': {
        return {
          ...state,
          assets: action.payload
        }
      }
      case 'append_assets': {
        let fullAssets = state.publishedAssets.concat(action.payload)
        return {
          ...state,
          assets: fullAssets
        }
      }
      case 'set_raw_assets': {
        return {
          ...state,
          rawAssets: action.payload
        }
      }
      case 'append_raw_assets': {
        let fullAssets = { ...state.rawAssets, ...action.payload }
        return {
          ...state,
          rawAssets: fullAssets
        }
      }
      case 'set_signed_assets': {
        return {
          ...state,
          signedAssets: action.payload
        }
      }
      case 'append_signed_assets': {
        let fullAssets = { ...state.signedAssets, ...action.payload }
        return {
          ...state,
          signedAssets: fullAssets
        }
      }
      case 'set_published_assets': {
        return {
          ...state,
          publishedAssets: action.payload
        }
      }
      case 'append_published_assets': {
        let fullAssets = { ...state.publishedAssets, ...action.payload }
        return {
          ...state,
          publishedAssets: fullAssets
        }
      }
      case 'set_modal_active': {
        return {
          ...state,
          modalActive: action.payload
        }
      }
      case 'set_modal_status': {
        return {
          ...state,
          modalStatus: action.payload
        }
      }
      case 'set_modal_data': {
        return {
          ...state,
          modalData: action.payload
        }
      }
      case 'add_tag': {
        let newAssets = [...state.publishedAssets];
        newAssets[action.payload.currentIdx].tags = action.payload.newTags;

        return {
          ...state,
          assets: newAssets
        }
      }
      case 'remove_tag': {
        let newAssets = [...state.publishedAssets];
        newAssets[action.payload.currentIdx].tags = action.payload.newTags;

        return {
          ...state,
          assets: newAssets
        }
      }
      default: {
      }
    }
    throw Error('Unknown action:' + action.type)
  }