import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import NavigationComponent from '../navigationComponent'
//import * as amplitude from '@amplitude/analytics-browser';

const BirthdayCategory = () => {
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [birthdays, setBirthdays] = useState([]);
    const [userId, setUserId] = useState(null);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [categoryCreated, setCategoryCreated] = useState(false);
    const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL
    const [showUpgradeLink, setShowUpgradeLink] = useState(false);

    useEffect(() => {
        //amplitude.track("Category Page Accessed", { category: 'Birthday' });

        Auth.currentAuthenticatedUser()
            .then(user => {
                setUserId(user.username);
            })
            .catch(err => console.error('Error fetching authenticated user', err));
    }, []);

    const validateCategoryData = (data) => {
        // Check for non-empty name
        if (!data.name || data.name.trim() === '') {
            setMessage('Name cannot be empty');
            return false;
        }

        // Check character limits
        if (data.name.length > 100) {
            setMessage('Name is too long');
            return false;
        }

        // Date validation
        if (!data.calendarAttrs.fromDate) {
            setMessage('Date is required');
            return false;
        }

        const birthdayDate = new Date(data.calendarAttrs.fromDate);
        const currentDate = new Date();
        if (birthdayDate > currentDate) {
            setMessage('Birthday date cannot be in the future');
            return false;
        }

        return true;
    };


    const customPostRequest = async (url, data) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorBody = await response.json().catch(() => ({}));
                const errorMessage = errorBody.message || `Server responded with status: ${response.status}`;
                throw new Error(errorMessage);
            }

            return await response.json().catch(e => {
                throw new Error(`Error parsing JSON: ${e.message}`);
            });
        } catch (error) {
            if (error.name === 'TypeError' && error.message.includes('NetworkError')) {
                throw new Error('Network error: Please check your internet connection.');
            }
            console.error('Error in customPostRequest:', error);
            throw error;
        }
    };


    async function fetchUserSubscription(userId) {
        const url = `${API_GATEWAY_URL}/v1/users/${userId}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });
            const data = await response.json();
            console.log("this is the data ", data);

            return {
                categoryCount: data.categoryCount,
                categoryLimit: data.subscription.categoryLimit
            };
        } catch (error) {
            console.error('Error fetching user subscription:', error);
            return null;
        }
    };

    const handleCreate = async () => {
        if (!userId) {
            setMessage('User not authenticated');
            setShowUpgradeLink(false);
            return;
        }

        if (!name || !date) {
            setMessage('Please enter both birthday name and date');
            setShowUpgradeLink(false);
            return;
        }

        try {
            const subscriptionData = await fetchUserSubscription(userId);
            if (!subscriptionData) {
                setMessage('Failed to fetch subscription data');
                setShowUpgradeLink(true);
                return;
            }

            const { categoryCount, categoryLimit } = subscriptionData;

            console.log("Subscription Data:", categoryCount +1 , categoryLimit);
            if (categoryCount >= categoryLimit) {
                setMessage('You have reached your category limit.');
                setShowUpgradeLink(true);
                return;
            }
        } catch (error) {
            console.error('Error fetching category count:', error);
            setMessage(`Failed to fetch category count: ${error.message}`);
            setShowUpgradeLink(true);
            return;
        }

        const categoryData = {
            userId: userId,
            name: name,
            type: "event",
            subType: "birthday",
            calendarAttrs: {
                fromDate: date + "T00:00:00Z",
                toDate: date + "T23:59:59Z",
                annual: true
            }
        };

        if (!validateCategoryData(categoryData)) {
            return;
        }

        try {
            const apiURL = `${process.env.REACT_APP_API_GATEWAY_URL}/k1/users/${userId}/categories`;
            const responseData = await customPostRequest(apiURL, categoryData);

            if (responseData.error) {
                throw new Error(responseData.error);
            }

            setBirthdays(prev => [...prev, { name, date }]);
            setName('');
            setDate('');
            setMessage('Category created successfully!');
            setCategoryCreated(true);
            //amplitude.track("Category Created", { category: 'Birthday', userId: userId });

        } catch (error) {
            console.error('Error in handleCreate:', error);
            setMessage(`Failed to create category: ${error.message}`);
        }
    };





    const handleUploadClick = () => {
        //amplitude.track("Upload Page Visited");
        navigate('/upload');
    };

    //Navigation icons
      function handleHomeClick(){
        navigate('/gallery');
      }

    return (
        <div className="birthday-category">
            <NavigationComponent handleHomeClick={handleHomeClick} />
            <h2 className="category-header">Birthday</h2>
            {message && <p className="message">{message}</p>}
            {showUpgradeLink && (
                <p>Please <span className="link-like" onClick={() => navigate('/subscribe')}>upgrade</span> your plan.</p>
            )}
            {message === 'Category created successfully!' && (
                <p><Link to="/gallery">Click here</Link> to return to the main gallery.</p>
            )}
            <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="input-field"
            />
            <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="input-field"
            />
            <div className="button-group">
                <button onClick={handleCreate} className="create-button">Create</button>
            </div>
            {categoryCreated && (
                <div className='list_and_upload'>
                    <ul>
                        {birthdays.map((birthday, index) => (
                            <li className="added-list" key={`${birthday.name}-${birthday.date}`}>{birthday.name} - {birthday.date}</li>
                        ))}
                    </ul>
                    <button className="upload-button-list" onClick={handleUploadClick}>Upload Photos</button>
                </div>
            )}
        </div>
    );
};

export default BirthdayCategory;
