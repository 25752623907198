import React from "react";
import '../../assets/stylesheets/footer.css';
import trademarkImg from '../../assets/images/kinstak-logo01.jpg';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className="footer">
            <div className="footer-background">
                <div className="footer-overlay" />
            </div>
            <div className="footer-logo-container">
                <img className="footer-logo" alt="Kinstak TM" src={trademarkImg} />
            </div>
            <div className="footer-text-container">
                <p className="footer-text">Copyright {currentYear} | Kin Technologies, Inc. &trade; All rights reserved.</p>
            </div>
        </div>
    );
};

export default Footer;
