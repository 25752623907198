const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL
//--------------------------UTILS-------------------------------------------------

function isEmptyArray(arr) {
  if (Array.isArray(arr) && !arr.length ) {
    return true
  }
  return false
}

function isEmptyObject(obj) {
  if (isEmptyArray(Object.keys(obj))){
    return true
  }
  return false
}

function createCategoryParam(categories){
  //console.log('createCategoryparams:', categories)
  return categories.map(category => {
    return `cat=${ category.id }&`
  }).join('')
}

function createNewUrl(url, params) {
  let gUrl = new URL(url);
  if (!isEmptyObject(params)) {
      const mock2 = Object.keys(params).map(key => {
        if (Array.isArray(params[key])) {
          let mock1 = params[key].map(pkey => {
            gUrl.searchParams.append(key, pkey)
            return true
          })
          console.log(mock1)
        } else {
          gUrl.searchParams.append( key, params[key])
        }
        return true
    })
    console.log(mock2)
  }
  //console.log('createnewurl:', gUrl.toString())
  return gUrl.toString()
}

//--------------------------GET REQUEST-------------------------------------------------

async function getRequest({ url, params = {} }) {
  try {
      let response;
      url = createNewUrl(url, params)
      //console.log('GetRequest URL: ', url)

      response = await fetch(url, {
      method: 'GET',
      headers: {
          'Accept': 'application/json'
          },
      })

      response = await response.json()
      //console.log('getRequest response:', response)

      return response
      } catch(error) {
          console.log('There was an error:', error)
          return error
      }
}

//--------------------------POST REQUEST-------------------------------------------------

async function postRequest(url, params = {}, payload) {
  let response;
  try {
    url = createNewUrl(url, params)
    //console.log('PostRequest URL: ', url)
    response = await fetch(url, {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
    body: JSON.stringify(payload)
    })
  response = await response.json()

  } catch (error) {
      console.log('There was an error:', error)
  }
  return response
}

async function deleteRequest(url, params = {}) {
  try {
    url = createNewUrl(url, params)
    console.log('DeleteRequest URL: ', url)
    let response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
    });

    response = await response.json();
    console.log('deleteRequest response:', response);
    return response;
  } catch(error) {
    console.error('There was an error with the delete request:', error);
    throw error; 
  }
}
//--------------------------REQUESTS-------------------------------------------------


export function getRequestAssets(config) {
  const currentPage = config.currentPage.current;
  const limitSize = config.pageLimit;
  const userId = config.userId;
  const categories = createCategoryParam(config.categories) || ''

  let url = `${ API_GATEWAY_URL }/v1/users/${ userId }/assets?${ categories }page=${ currentPage }&limit=${ limitSize }`
  let response = getRequest({ url })
  config.currentPage.current += 1;
  return response
}

export function fetchDuplicates() {
  let url = `${ API_GATEWAY_URL }/v1/categories/system/duplicate`
  let response = getRequest({ url })
  return response
}
export function fetchFavorites() {
  let url = `${ API_GATEWAY_URL }/v1/categories/system/favorite`
  let response = getRequest({ url })
  return response
}
export async function fetchAssetsByCategory({categoryId, userId}) {
  let url = `${API_GATEWAY_URL}/v1/users/${userId}/assets?cat=${categoryId}`;
  try {
    let response = await getRequest({ url });
    console.log('fetchAssetsByCategory:', response)
    return response;
  } catch (error) {
    console.error(`Error fetching assets for category ${categoryId}:`, error);
    throw error;
  }
}

export function postFavorites(config) {
  let url = `${ API_GATEWAY_URL }/v1/users/${config?.userId}/categorizeassets`
  let payload = config.payload
  return postRequest(url, {}, payload)
}

export function deleteAsset(config) {
  let url = `${ API_GATEWAY_URL }/v1/users/${config?.userId}/assets/${config?.assetId}`
  let payload = config.payload
  return deleteRequest(url, {}, payload)
}

//userId, keysForSigning
export function postRequestSignedUrls(config) {
  let url = `${ API_GATEWAY_URL }/v1/users/${ config?.userId }/signurls`
  let payload = config?.payload
  //console.log(config?.log)
  return postRequest(url, {}, payload)
}

export function postRequestCustomerPortalSession(config) {
  let url = `${ API_GATEWAY_URL }/k1/subscription/billing/session`
  let payload = config?.payload
  //console.log(config?.log)
  return postRequest(url, {}, payload)
}

export function getRequestUserCategories(config) {
  let userId = config.userId || "00000000-0000-0000-0000-000000000000"
  let url = `${ API_GATEWAY_URL }/v1/users/${ userId }/topcategories`
  console.log(config?.log)
  return getRequest({ url })
}

export function getRequestCategoryBySystemName(config) {
  //let userId = config?.userId || "00000000-0000-0000-0000-000000000000"
  let url = `${ API_GATEWAY_URL }/v1/categories/system/${ '2017' }`
  console.log(config?.log)
  return getRequest({ url })
}

export function getRequestS3Urls(config) {
  let userId = config?.userId || "00000000-0000-0000-0000-000000000000"
  let assetId = config?.assetId || "DDDDDDDD-55F7-4B8F-A63E-EA4100004D51"

  let url = `${ API_GATEWAY_URL }/v1/users/${ userId }/assets/${ assetId}`
  console.log(config?.log)
  return getRequest({ url })
}

export function getRequestCategoryName(config) {
  let categoryId = config?.categoryId || "9238ffd6-52e6-49e8-864a-2902b8939f28"

  let url = `${ API_GATEWAY_URL }/v1/categories/${ categoryId }`
  console.log(config?.log)
  return getRequest({ url })
}

export function getRequestAssetsByQuery(config) {
  let userId = config?.userId || "00000000-0000-0000-0000-000000000000"
  let url = `${ API_GATEWAY_URL }/v1/users/${ userId }/assets`
  let params = config?.params;

  //console.log('params:',config)
  return getRequest({ url, params: params })
}
