import React, {useEffect, useState} from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { useTheme, View, Text, Heading, useAuthenticator, Button} from '@aws-amplify/ui-react';
import '../assets/stylesheets/auth.css';
import {useNavigate} from 'react-router-dom'
import { Hub } from 'aws-amplify';
import AuthLogo from '../assets/images/auth_logo.png';
import EmbeddedWebView from './embeddedWebView';


const CustomHeader = () => {
  return (
    <h1 className="auth-title">Create Your Kin Account</h1>
  );
};

const components = {

  Header: CustomHeader,

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },

    SignIn: {
      Footer: SignInFooter,
    },

    SignUp: {
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },

    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },

    }
  };

  const formFields = {
    signIn: {
      username: {
        placeholder: 'Enter your email',
        label: 'Email'
      },
    },
    signUp: {
        username: {
          placeholder: 'Enter your email',
          label: 'Email',
          isRequired: true,
          order: 1,
        },
        password: {
          label: 'Password:',
          placeholder: 'Enter your Password:',
          isRequired: true,
          order: 2,
        },
        confirm_password: {
          label: 'Confirm Password:',
          order: 0,
        },
      },
    forceNewPassword: {
      password: {
        placeholder: 'Enter your Password:',
      },
    },
    resetPassword: {
        username: {
            placeholder: 'Enter your email',
            label: 'Email',
            isRequired: true,
            order: 1,
          },
    },
    confirmResetPassword: {
        confirmation_code: {
          placeholder: 'Enter your Confirmation Code:',
          label: 'Confirmation Code',
          isRequired: true,
          order: 0,
        },
        password: {
          placeholder: 'Enter your new password',
          label: 'New Password',
          isRequired: true,
          order: 1,
        },
      },
    setupTOTP: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
  };

  const LeftPanel = () => {
    return (
      <div className="left-panel">
        <img className="auth_img" src={AuthLogo} alt="Auth Logo" />
        <ol className="login_instructions">
        <li className='login_list'>Sign-up for Free</li>
          <li className='login_list'>Upload Photos <br></br><p>Unlimited Storage!</p></li>
          <li className='login_list'>View Organized Gallery</li>
          <li className='login_list'>Create Kin-Categories</li>
        </ol>
      </div>
    );
  };

  const RightPanel = () => {
    return (
      <div className="right-panel">
        <Authenticator formFields={formFields} components={components}>
        </Authenticator>
      </div>
    );
  };

   function SignInFooter(){
    const { toResetPassword } = useAuthenticator();
    const [view, setView] = useState('');

    const handleOpenView = (viewName) => {
      setView(viewName);
    };

    if (view === 'privacy') {
      return <EmbeddedWebView url="https://kinstak.com/terms-and-conditions/privacy-policy/" />;
    } else if (view === 'terms') {
      return <EmbeddedWebView url="https://kinstak.com/terms-and-conditions/" />;
    }

    return (
      <View textAlign="center">
        <Text className="terms-and-privacy-text">
          By using Kinstak you agree to the <Button onClick={() => handleOpenView('terms')} className="terms-and-privacy-btn">Terms of Service</Button> & <Button onClick={() => handleOpenView('privacy')} className="terms-and-privacy-btn">Privacy Policy</Button>
        </Text>
        <Button
          fontWeight="normal"
          onClick={toResetPassword}
          size="small"
          variation="link"
          className='reset-password-btn'
        >
          Reset Password
        </Button>
      </View>
    );
  };


export default function Authx() {
  const navigate = useNavigate();

  useEffect(() => {
    const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;
    const inParams = new URLSearchParams(window.location.search);
    const isCanvaUser = inParams.get('canva_user')
    const kinstakUserId = localStorage.getItem("username");
    const canvaUserId = inParams.get('userId')
    if ((isCanvaUser === "true") && (kinstakUserId !== "null")) {
      const params = new URLSearchParams({
        state: inParams.get('state'),
        canvaUserId: canvaUserId,
        userId: kinstakUserId
      });
      window.location.replace(`${ API_GATEWAY_URL }/k1/canva/success?${params.toString()}`);
    }
  }, [])

  useEffect(() => {
    Hub.listen('auth', ({payload: { event, data }}) => {
      const inParams = new URLSearchParams(window.location.search);
      const isCanvaUser = inParams.get('canva_user')
      const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;
      console.log('event', event)

      if (event !== 'signIn') return
      console.log('aud:', inParams.get('aud'))
      console.log('userId:', data.username)
      if ((isCanvaUser === "true") && (data.username !== null)) {
        const params = new URLSearchParams({
          state: inParams.get('state'),
          canvaUserId: inParams.get('userId'),
          userId: data.username
        });
        window.location.replace(`${ API_GATEWAY_URL }/k1/canva/success?${params.toString()}`);
      } else {
        navigate('/upload');
      }
    });

    //return () => Hub.remove('auth', listener);
  }, [navigate]);

    return (
      <div className="auth-wrapper">
        <div className="auth-form-container">
          <LeftPanel />
          <div className='right-auth-panel'>
            <RightPanel />
          </div>
        </div>
      </div>
    );
  }
