import React, { useState } from 'react';
import rightArrow from '../../assets/images/46_Arrow_Right.jpg';
import reloadArrow from '../../assets/images/reload-arrow2.jpg';
import cat1 from '../../assets/images/cat1.jpg';
import cat2 from '../../assets/images/cat2.jpg';
import dog1 from '../../assets/images/dog1.jpg';
import dog2 from '../../assets/images/dog2.jpg';
import dog3 from '../../assets/images/dog3.jpg';

const PetsCategory = () => {
    const [petName, setPetName] = useState(''); 
    const [members, setMembers] = useState([]);
    const [avatarStartIndex, setAvatarStartIndex] = useState(0);
    const avatars = [cat1, cat2, dog1, dog2, dog3];

    const handleAddPet = () => {
        if (petName) {
            setMembers(prev => [...prev, petName]); 
            setPetName(''); 
        }
    };

    const handleShowMoreAvatars = () => {
        if (avatarStartIndex + 4 < avatars.length) {
            setAvatarStartIndex(prev => prev + 4);
        } else {
            setAvatarStartIndex(0);
        }
    };

    const isAtEnd = avatarStartIndex + 4 >= avatars.length;

    return (
        <div className="friends-family-category">
            <h2 className="category-header">Pets</h2>
            <input
                type="text"
                placeholder="Pet Name"
                value={petName}
                onChange={(e) => setPetName(e.target.value)}
                className="input-field"
            />
            <div className='avatars-and-arrow'>
                <div className="members-icons">
                    {avatars.slice(avatarStartIndex, avatarStartIndex + 4).map((avatar, index) => (
                        <div key={index} className="member-icon">
                            <div className="icon">
                                <img src={avatar} alt={`User ${index + avatarStartIndex + 1}`} />
                            </div>
                            <span className="icon-name">Name {index + avatarStartIndex + 1}</span>
                        </div>
                    ))}
                </div>
                <div className="member-icon arrow-icon" onClick={handleShowMoreAvatars}>
                    <img src={isAtEnd ? reloadArrow : rightArrow} alt={isAtEnd ? "Reload" : "Next"} className="icon-image" />
                </div>
            </div>

            <div className="button-group">
                <button onClick={handleAddPet} className="create-button">Create</button>
                <button onClick={() => {
                    handleAddPet();
                    setPetName('');
                }} className="add-button">+Add</button>
            </div>
            
            {/* Listing the pets */}
            <ul>
                {members.map((member, index) => (
                    <li className="added-list" key={index}>{member}</li>
                ))}
            </ul>
        </div>
    );
};

export default PetsCategory;
