import React from 'react';
//import { useDispatch } from 'react-redux';
//import { Navigate } from "react-router-dom";
//import { Auth } from 'aws-amplify';
//import { login } from '../store/user'

const Dashboard = (props) => {
    //const dispatch = useDispatch();

    // Auth.currentAuthenticatedUser().then( data => {
    //     //if (data.username) {
    //     //    dispatch(login(data.username));}
    // })

    //const { user } = useSelector(state => state.user)

    return (
        <>   
            <h3>Dashboard</h3>
        </>
    )
};

export default Dashboard;