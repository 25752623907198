import lftDarkArrow from "../../assets/images/prev.png";
import rtDarkArrow from "../../assets/images/next.png";
import lftArrow from "../../assets/images/lft-arrow.png";
import rtArrow from "../../assets/images/rt-arrow.png";


export function calculateProgressBar(progressBar) {
    progressBar.innerHTML = ""
    const slider = progressBar.closest('.slider-header').nextSibling.children[1].children[0]
    let itemCount = slider.children.length;

    let width = window.innerWidth
    let itemsPerScreen = 0;
  
    switch(true){
        case width > 991:
          //four children
          itemsPerScreen = 4
          break;
        case width > 767 && width <= 991:  
          // three children
          itemsPerScreen = 3
          break;
        case width > 575 && width < 768:
          itemsPerScreen = 2
          break;
        default:
          itemsPerScreen = 1
    }
    
    slider.style.setProperty('--items-per-view', itemsPerScreen);
    
    const progressBarTickCount = Math.ceil(itemCount / itemsPerScreen )
    let cs = getComputedStyle(slider);
    let sliderIdx = parseInt(cs.getPropertyValue('--slider-index'))
    let leftArrow = slider.closest('.main-level01-row').querySelector('.lft-arrow > .scroll-arrow')
    let rightArrow = slider.closest('.main-level01-row').querySelector('.rt-arrow > .scroll-arrow')

    if (sliderIdx === 0 && progressBarTickCount === 1) {
      leftArrow.classList.remove('active')
      leftArrow.src = lftArrow
      rightArrow.classList.remove('active')
      rightArrow.src = rtArrow
    }
    if (sliderIdx === 0 && progressBarTickCount > 1) {
      leftArrow.classList.remove('active')
      leftArrow.src = lftArrow
      rightArrow.classList.add('active')
      rightArrow.src = rtDarkArrow
    }

    //console.log('slideridx:',sliderIdx)

    for (let x = 0; x < progressBarTickCount; x++ ) {
      let tickItem = document.createElement('div');
      tickItem.classList.add('progress-tick')
      if (x === sliderIdx) {
        tickItem.classList.add('active')
      }
      progressBar.append(tickItem)
    }
  }

  export function handleRowScroll(e, direction) {

      let slider = e.target.closest('.scroll-container')

      let leftArrow = slider.closest('.main-level01-row').querySelector('.lft-arrow > .scroll-arrow')
      let rightArrow = slider.closest('.main-level01-row').querySelector('.rt-arrow > .scroll-arrow')

      let progressBar = slider.closest('.main-level01-row').previousSibling.children[1];
      // Get reference to left and right arrow

      if (direction === 'left') {
        slider = slider.nextSibling.querySelector('.thumb-slider')
      } else {
        slider = slider.previousSibling.querySelector('.thumb-slider')
      }
      let cs = getComputedStyle(slider);
      let sliderIdx = parseInt(cs.getPropertyValue('--slider-index'))
      let itemsPerView = parseInt(cs.getPropertyValue('--items-per-view'))
      let widthPerBlock = parseInt(cs.getPropertyValue('--width-per-block'))
      //console.log('slideridx:',sliderIdx)

      let progressBarTicks = progressBar.children.length;
      // 1180 width 4 items 295 per block
      // 900  width 4 items 225
      // 700  width 4 items 175
      if (direction === 'left' && sliderIdx > 0) {
        let totalTranslatex = ((sliderIdx - 1) * itemsPerView * widthPerBlock) + 'px'
        console.log('totalTransltaex:', totalTranslatex)
        slider.style.setProperty("--slider-index", sliderIdx - 1 );
        slider.style.setProperty("--total-translatex", totalTranslatex );

        progressBar.children[sliderIdx]?.classList.remove('active');
        progressBar.children[sliderIdx - 1]?.classList.add('active');
      
        if (sliderIdx - 1 === 0 && progressBar.children.length - 1 > 0) {
          leftArrow.classList.remove('active')
          leftArrow.src = lftArrow;

          rightArrow.classList.add('active')
          rightArrow.src = rtDarkArrow;
        }
        if (sliderIdx - 1 === progressBar.children.length - 1) {
          leftArrow.classList.add('active')
          leftArrow.src = lftDarkArrow
          rightArrow.classList.remove('active')
          rightArrow.src = rtArrow
        }
        if (sliderIdx - 1 > 0 && sliderIdx -1 < progressBar.children.length - 1) {
          leftArrow.classList.add('active')
          rightArrow.classList.add('active')
        }
        // if progressBar.children is first
        // add active to left arrow

      } else if (direction === 'right' && sliderIdx + 1 < progressBarTicks) {
        let totalTranslatex = ((sliderIdx + 1) * itemsPerView * widthPerBlock) + 'px'
        console.log('totalTransltaex:', totalTranslatex)

        slider.style.setProperty("--slider-index", sliderIdx + 1);
        slider.style.setProperty("--total-translatex", totalTranslatex );

        progressBar.children[sliderIdx]?.classList.remove('active');

        progressBar.children[sliderIdx + 1]?.classList.add('active');

        if (sliderIdx + 1 === 0 && progressBar.children.length - 1 > 0) {
          leftArrow.classList.remove('active')
          leftArrow.src = lftArrow
          rightArrow.classList.add('active')
          rightArrow.src = rtDarkArrow
        }
        if (sliderIdx + 1 === progressBar.children.length - 1) {
          leftArrow.classList.add('active')
          leftArrow.src = lftDarkArrow
          rightArrow.classList.remove('active')
          rightArrow.src = rtArrow
        }
        if (sliderIdx + 1 > 0 && sliderIdx + 1 < progressBar.children.length - 1) {
          leftArrow.classList.add('active')
          leftArrow.src = lftDarkArrow
          rightArrow.classList.add('active')
          rightArrow.src = rtDarkArrow
        }

        // if progressBar.children is last
        // add active to right arrow
      }

    
  }